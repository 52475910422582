import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { serverLink } from "../../resources/Url";
import { showAlert } from "../../pages/sweetalert/sweetalert";
import axios from "axios";
import { setLoginDetails } from "../../actions/Actions";
import { connect } from "react-redux";
import NavBar from "../NavBar";
import Footer from "../Footer";
import Table from "../../pages/table/table";
import Header from "../Header";
import HomeContentLoader from "../HomeContentLoader/HomeContentLoader";
import SubmittingLoader from "../SubmittingLoader/SubmittingLoader";

function AddNews(props) {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;
  const currentDay = currentDate.getDate();
  const navigate = useNavigate();
  const [currentUser] = useState(
    typeof props?.UserProfile !== "undefined" ? props?.UserProfile : []
  );
  const nALDALoginHistory =
    JSON.parse(localStorage.getItem("NALDALoginHistory")) || [];
  const [position, setPosition] = useState(0);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedFile1, setSelectedFile1] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);
  const [selectedFile3, setSelectedFile3] = useState(null);
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);

  const [createItem, setCreateItem] = useState({
    NewsId: "",
    Title: "",
    ParagraphOne: "",
    ParagraphTwo: "",
    ParagraphThree: "",
    StaffId: "",
    CreatedDate: "",
    IsActive: "",
    CoverImage: "",
    NewsImage: "",
    LatestImage: "",
    Video: "",
  });

  const resetItem = () => {
    setCreateItem({
      ...createItem,
      NewsId: "",
      Title: "",
      ParagraphOne: "",
      ParagraphTwo: "",
      ParagraphThree: "",
      StaffId: "",
      CreatedDate: "",
      IsActive: "",
      CoverImage: "",
      NewsImage: "",
      LatestImage: "",
      Video: "",
    });
  };

  const [datatable, setDatatable] = useState({
    columns: [
      {
        label: "S/N",
        field: "sn",
      },
      {
        label: "Title",
        field: "Title",
      },
      {
        label: "ParagraphOne",
        field: "ParagraphOne",
      },
      {
        label: "Action",
        field: "action",
      },
    ],
    rows: [],
  });

  const onEdit = async (e) => {
    const id = e.target.id;
    const value = e.target.value;

    if (e.target.type === "file") {
      if (id === "image1") {
        setSelectedFile1(e.target.files[0]);
      }
      if (id === "image2") {
        setSelectedFile2(e.target.files[0]);
      }
      if (id === "image3") {
        setSelectedFile3(e.target.files[0]);
      }
    } else {
      setCreateItem({
        ...createItem,
        [id]: value,
      });
    }
  };

  const getProjects = async () => {
    await axios
      .get(`${serverLink}shared/get/news`)
      .then((result) => {
        if (result.data.length > 0) {
          let rows = [];
          result.data.map((staff, index) => {
            rows.push({
              sn: index + 1,
              NewsId: staff.NewsId,
              Title: staff.Title,
              ParagraphOne: staff.ParagraphOne,
              ParagraphTwo: staff.ParagraphTwo,
              ParagraphThree: staff.ParagraphThree,
              StaffId: staff.StaffId,
              CreatedDate: staff.CreatedDate,
              IsActive: staff.IsActive,
              CoverImage: staff.CoverImage,
              NewsImage: staff.NewsImage,
              LatestImage: staff.LatestImage,
              Video: staff.Video,
              action: (
                <button
                  type="button"
                  className="btn btn-primary"
                  style={{ backgroundColor: "#008435" }}
                  data-toggle="modal"
                  data-target="#myModal"
                  onClick={() =>
                    setCreateItem({
                      NewsId: staff.NewsId,
                      Title: staff.Title,
                      ParagraphOne: staff.ParagraphOne,
                      ParagraphTwo: staff.ParagraphTwo,
                      ParagraphThree: staff.ParagraphThree,
                      StaffId: staff.StaffId,
                      CreatedDate: staff.CreatedDate,
                      IsActive: staff.IsActive,
                      CoverImage: staff.CoverImage,
                      NewsImage: staff.NewsImage,
                      LatestImage: staff.LatestImage,
                      Video: staff.Video,
                    })
                  }
                >
                  Edit
                </button>
              ),
            });
          });

          setDatatable({
            ...datatable,
            columns: datatable.columns,
            rows: rows,
          });
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("NETWORK ERROR");
      });
  };

  useEffect(() => {
    getProjects().then((r) => {});
    const timeoutId = setTimeout(() => {
      setPosition(1);
      setIsLoading(false);
      if (currentUser.length === 0) {
        navigate("/auth");
      }
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [currentUser]);

  const uploadImage = async (formData, endpoint) => {
    try {
      const response = await axios.post(`${serverLink}${endpoint}`, formData);
      if (response.data.type === "success") {
        return response.data.file.url;
      }
      throw new Error("Image upload failed.");
    } catch (error) {
      console.error("Image upload error:", error);
      throw error;
    }
  };

  const onSubmit = async (e) => {
    setIsSubmittingForm(true);
    e.preventDefault();
    for (let key in createItem) {
      if (
        createItem.hasOwnProperty(key) &&
        key !== "NewsId" &&
        key !== "ParagraphTwo" &&
        key !== "ParagraphThree" &&
        key !== "CoverImage" &&
        key !== "NewsImage" &&
        key !== "StaffId" &&
        key !== "CreatedDate" &&
        key !== "Video" &&
        key !== "LatestImage"
      ) {
        if (createItem[key] === "") {
          await showAlert("EMPTY FIELD", `Please enter ${key}`, "error");
          setIsSubmittingForm(false);
          return false;
        }
      }
    }


    // Check image sizes
    const maxSizeKB = 2024;
    const imageChecks = [
      createItem.CoverImage,
      createItem.NewsImage,
      createItem.LatestImage,
    ];

    for (const image of imageChecks) {
      if (image && image.size / 1024 > maxSizeKB) {
        await showAlert("", `File Size Can't be more than 2MB`, "error");
        setIsSubmittingForm(false);
        return;
      }
    }

    if (error === "invalid") {
      await showAlert("Input Error", "Invalid Email Address", "error");
      setIsSubmittingForm(false);
      return false;
    }

    if (selectedFile1) {
      const image1 = new FormData();
      image1.append("image1", selectedFile1);

      const image2 = new FormData();
      image2.append("image2", selectedFile2);

      const image3 = new FormData();
      image3.append("image3", selectedFile3);


      const image1_ = await uploadImage(image1, "news/upload/image1");
      const image2_ = await uploadImage(image2, "news/upload/image2");
      const image3_ = await uploadImage(image3, "news/upload/image3");

      const sendData = {
        NewsId: createItem.NewsId,
        Title: createItem.Title,
        ParagraphOne: createItem.ParagraphOne,
        ParagraphTwo: createItem.ParagraphTwo,
        ParagraphThree: createItem.ParagraphThree,
        StaffId: currentUser.StaffID,
        CreatedDate: `${currentMonth}/${currentDay}/${currentYear}`,
        IsActive: createItem.IsActive,
        CoverImage: image1_,
        NewsImage: image2_,
        LatestImage: image3_,
        Video: createItem.Video,
      };
      if (createItem.NewsId === "") {
        axios
            .post(`${serverLink}news/add`, sendData)
            .then(async (res) => {
              if (res.data.message === "success") {
                resetItem();
                await getProjects();
                await showAlert(
                    "Success",
                    `News Added Successfully`,
                    "success"
                );
                setIsSubmittingForm(false);
              }
              if (res.data.message === "exist") {
                await showAlert(
                    "Error",
                    `News with given details already exist!`,
                    "error"
                );
                setIsSubmittingForm(false);
              }
            })
            .catch((error) => {
              console.log("Error", error);
            });
      }
      if (createItem.NewsId !== "") {
        await axios
            .patch(`${serverLink}news/update`, sendData)
            .then((result) => {
              if (result.data.message === "success") {
                resetItem();
                getProjects();
                showAlert(
                    "Success",
                    "News Updated Successfully",
                    "success"
                );
                setIsSubmittingForm(false);
              } else {
                showAlert(
                    "ERROR",
                    "Something went wrong. Please try again!",
                    "error"
                );
                setIsSubmittingForm(false);
              }
            })
            .catch((error) => {
              showAlert(
                  "NETWORK ERROR",
                  "Please check your connection and try again!",
                  "error"
              );
              setIsSubmittingForm(false);
            });
      }
    }
  };

  return (
    <>
      {isLoading ? (
        <HomeContentLoader />
      ) : (
        <div id="wrapper">
          <Header />
          <NavBar />
          <div id="page-wrapper">
            <div class="header">
              <h1 class="page-header">Manage News</h1>
            </div>
            <div id="page-inner">
              <div class="row">
                <div class="col-md-12">
                  <div class="panel panel-default">
                    <div class="panel-heading"></div>

                    <div class="panel-body">
                      <div className="panel-body">
                        <button
                          style={{
                            backgroundColor: "#008435",
                            float: "right",
                          }}
                          className="btn btn-primary"
                          data-toggle="modal"
                          data-target="#myModal"
                          onClick={resetItem}
                        >
                          Add News
                        </button>
                        {isSubmittingForm && <SubmittingLoader />}
                        <div
                          className="modal fade"
                          id="myModal"
                          tabIndex="-1"
                          role="dialog"
                          aria-labelledby="myModalLabel"
                          aria-hidden="true"
                        >
                          <div className="modal-dialog">
                            <div
                              className="modal-content"
                              style={{
                                width: "700px",
                              }}
                            >
                              <div className="modal-header">
                                <button
                                  type="button"
                                  className="close"
                                  data-dismiss="modal"
                                  aria-hidden="true"
                                >
                                  &times;
                                </button>
                                <h4 className="modal-title" id="myModalLabel">
                                  News Component
                                </h4>
                              </div>
                              <div className="modal-body">
                                <div className="col-lg-6 mb-4">
                                  <div className="form-group">
                                    <input
                                      className="form-control"
                                      id="Title"
                                      type="text"
                                      placeholder="Title"
                                      value={createItem.Title}
                                      onChange={onEdit}
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-6 ">
                                  <div className="form-group">
                                    <select
                                      id="IsActive"
                                      className="form-control"
                                      value={createItem.IsActive}
                                      onChange={onEdit}
                                    >
                                      <option value="">Select Status</option>
                                      <option value="1">Active</option>
                                      <option value="0">InActive</option>
                                    </select>
                                  </div>
                                </div>

                                <div className="col-lg-12 mb-4">
                                  <div className="form-group">
                                    <input
                                        className="form-control"
                                        id="Video"
                                        type="text"
                                        placeholder="Video Link (Optional)"
                                        value={createItem.Video}
                                        onChange={onEdit}
                                    />
                                  </div>
                                </div>

                                <div className="col-lg-12 mb-4">
                                  <div className="form-group">
                                    <textarea
                                      className="form-control"
                                      id="ParagraphOne"
                                      type="text"
                                      style={{
                                        height: "120px",
                                      }}
                                      placeholder="Paragraph One"
                                      value={createItem.ParagraphOne}
                                      onChange={onEdit}
                                    ></textarea>
                                  </div>
                                </div>

                                <div className="col-lg-12 mb-4">
                                  <div className="form-group">
                                    <textarea
                                      className="form-control"
                                      id="ParagraphTwo"
                                      type="text"
                                      style={{
                                        height: "120px",
                                      }}
                                      placeholder="Paragraph Two"
                                      value={createItem.ParagraphTwo}
                                      onChange={onEdit}
                                    ></textarea>
                                  </div>
                                </div>

                                <div className="col-lg-12 mb-4">
                                  <div className="form-group">
                                    <textarea
                                      className="form-control"
                                      id="ParagraphThree"
                                      type="text"
                                      style={{
                                        height: "120px",
                                      }}
                                      placeholder="Paragraph Three"
                                      value={createItem.ParagraphThree}
                                      onChange={onEdit}
                                    ></textarea>
                                  </div>
                                </div>

                                <div className="col-lg-6 mb-4">
                                  <div className="form-group">
                                    <label htmlFor="image1">Front Image</label>
                                    <input
                                      type="file"
                                      accept=".jpg, .png"
                                      id="image1"
                                      name="image1"
                                      placeholder="File"
                                      onChange={(e) =>
                                        setSelectedFile1(e.target.files[0])
                                      }
                                    />
                                    <span className="alert-info">
                                      Only .png, .jpg
                                    </span>
                                  </div>
                                </div>

                                <div className="col-lg-6 mb-4">
                                  <div className="form-group">
                                    <label htmlFor="image2">News Image</label>
                                    <input
                                      type="file"
                                      accept=".jpg, .png"
                                      id="image2"
                                      name="image2"
                                      placeholder="File"
                                      onChange={(e) =>
                                        setSelectedFile2(e.target.files[0])
                                      }
                                    />
                                    <span className="alert-info">
                                      Only .png, .jpg
                                    </span>
                                  </div>
                                </div>

                                <div className="col-lg-12 mb-4">
                                  <div className="form-group">
                                    <label htmlFor="image3">
                                      Latest News Image
                                    </label>
                                    <input
                                      type="file"
                                      accept=".jpg, .png"
                                      id="image3"
                                      name="image3"
                                      placeholder="File"
                                      onChange={(e) =>
                                        setSelectedFile3(e.target.files[0])
                                      }
                                    />
                                    <span className="alert-info">
                                      Only .png, .jpg
                                    </span>
                                  </div>
                                </div>

                              </div>

                              <div className="modal-footer">
                                <button
                                  type="button"
                                  className="btn btn-default"
                                  data-dismiss="modal"
                                  style={{
                                    backgroundColor: "#da3535",
                                    color: "white",
                                  }}
                                  onClick={getProjects}
                                >
                                  Close
                                </button>
                                <button
                                  type="button"
                                  style={{ backgroundColor: "#008435" }}
                                  className="btn btn-primary"
                                  onClick={onSubmit}
                                >
                                  Save changes
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div class="panel panel-default">
                          <div class="panel-heading">News List</div>
                          <div className="card-body pt-0">
                            <Table data={datatable} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    HomePageData: state.HomePageContents,
    UserProfile: state.LoginDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setOnLoginDetails: (p) => {
      dispatch(setLoginDetails(p));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddNews);

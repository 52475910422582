import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { encryptData, serverLink } from "../resources/Url";
import { createGlobalStyle } from "styled-components";
import backgroundImage from "../resources/mibackground.png";
import logoImage from "../resources/lock.svg";
import { showAlert } from "./sweetalert/sweetalert";
import axios from "axios";
import { isMobile } from "react-device-detect";
import { setLoginDetails } from "../actions/Actions";
import HomeContentLoader from "./HomeContentLoader/HomeContentLoader";

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-color: #DEDEDEFF;
  }
`;

function Authentication(props) {
  const location = useLocation();
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [currentUser] = useState(
    typeof props?.UserProfile !== "undefined" ? props?.UserProfile : []
  );

  const pollResults = location.pathname
    .split("/")
    .filter((segment) => segment !== "");
  const pollPath = pollResults[0];

  const navigate = useNavigate();
  const [createItem, setCreateItem] = useState({
    StaffID: "",
    Email: "",
    Password: "",
  });

  const onLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    for (let key in createItem) {
      if (createItem.hasOwnProperty(key) && key !== "StaffID") {
        if (createItem[key] === "") {
          await showAlert("EMPTY FIELD", `Please enter ${key}`, "error");
          setIsLoading(false);
          return false;
        }
      }
    }

    if (error === "invalid") {
      await showAlert("Input Error", "Invalid Email Address", "error");
      setIsLoading(false);
      return false;
    }

    if (createItem) {
      const sendData = {
        Email: createItem.Email,
        Password: encryptData(createItem.Password),
      };

      console.log(sendData);

      if (sendData) {
        axios
          .post(`${serverLink}auth/login`, sendData)
          .then(async (res) => {
            if (res.data.message === "success") {
              const loginData = {
                StaffID: res.data.profile[0].StaffID,
                Email: res.data.profile[0].Email,
                FullName: res.data.profile[0].FullName,
                Appointment: res.data.profile[0].Appointment,
                Gender: res.data.profile[0].Gender,
                Permission: res.data.profile[0].Permission,
                Biography: res.data.profile[0].Biography,
              };
              localStorage.setItem(
                "NALDALoginHistory",
                JSON.stringify(loginData)
              );
              props.setOnLoginDetails(loginData);
              setTimeout(() => {
                navigate("/home");
                window.location.reload();
                setIsLoading(false);
              }, 2000);
            }
            if (res.data.message === "not") {
              await showAlert(
                "Wrong Login Data",
                `Sorry, email and password are wrong.`,
                "error"
              );
              setIsLoading(false);
            }
          })
          .catch((error) => {
            console.log("Error", error);
          });
      }
    }
  };

  const isValidEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const onEdit = (e) => {
    const id = e.target.id;
    const value = e.target.value;

    if (id === "Email") {
      const result = isValidEmail(value);

      if (result === null) {
        setError("invalid");
      } else {
        setError("valid");
      }
    }

    setCreateItem({
      ...createItem,
      [id]: value,
    });
  };

  useEffect(() => {
    if (currentUser && currentUser.StaffID > 0 && !"results") {
      navigate("/home");
    }
  }, [currentUser]);

  return (
    <>
      {isLoading ? (
        <HomeContentLoader />
      ) : (
        <div>
          <GlobalStyle />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "100vh",
            }}
          >
            <div
              style={{
                flex: 1,
                minHeight: "100vh",
                backgroundImage: `url(${backgroundImage})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            />
            <form
              style={{
                backgroundColor: "#dedede",
                padding: "30px",
                minWidth: "300px",
                width: "50%",
                maxWidth: "600px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "30px",
                }}
              >
                <img
                  src={logoImage}
                  alt="Logo"
                  style={{ height: "150px", marginRight: "10px" }}
                />
              </div>
              <div style={{ marginBottom: "20px" }}>
                <label
                  htmlFor="username"
                  style={{
                    display: "block",
                    marginBottom: "5px",
                    fontSize: "18px",
                    color: "#333",
                  }}
                >
                  Email Address
                </label>
                <input
                  type="text"
                  id="Email"
                  style={{
                    display: "block",
                    width: "100%",
                    padding: "10px",
                    borderRadius: "5px",
                    border: "1px solid #ddd",
                    fontSize: "16px",
                  }}
                  placeholder="Email Address *"
                  aria-required="true"
                  value={createItem.Email}
                  onChange={onEdit}
                />
                {error === "invalid" && (
                  <span
                    style={{
                      backgroundColor: "red",
                      color: "white",
                      fontSize: "12px",
                      padding: "5px",
                      marginTop: "5px",
                    }}
                  >
                    {error && (
                      <>{error === "invalid" && "Email is not valid"}</>
                    )}
                  </span>
                )}
              </div>
              <div style={{ marginBottom: "20px" }}>
                <label
                  htmlFor="password"
                  style={{
                    display: "block",
                    marginBottom: "5px",
                    fontSize: "18px",
                    color: "#333",
                  }}
                >
                  Password
                </label>
                <input
                  type="password"
                  id="Password"
                  style={{
                    display: "block",
                    width: "100%",
                    padding: "10px",
                    borderRadius: "5px",
                    border: "1px solid #ddd",
                    fontSize: "16px",
                  }}
                  placeholder="Password *"
                  aria-required="true"
                  value={createItem.Password}
                  onChange={onEdit}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <div>
                  <button
                    onClick={onLogin}
                    type="submit"
                    style={{
                      backgroundColor: "#0077cc",
                      color: "#fff",
                      padding: "10px 20px",
                      width: `${isMobile ? "240px" : "530px"}`,
                      borderRadius: "5px",
                      border: "none",
                      fontSize: "16px",
                      cursor: "pointer",
                      transition: "background-color 0.3s ease-in-out",
                    }}
                  >
                    Login
                  </button>
                </div>
                <div>
                  <button
                    type="button"
                    style={{
                      backgroundColor: "transparent",
                      border: "none",
                      color: "#333",
                      textDecoration: "underline",
                      fontSize: "16px",
                      cursor: "pointer",
                    }}
                  >
                    Forgot Password?
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    HomePageData: state.HomePageContents,
    UserProfile: state.LoginDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setOnLoginDetails: (p) => {
      dispatch(setLoginDetails(p));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Authentication);

import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./Home";
import Authentication from "./Auhentication";
import { setLoginDetails } from "../actions/Actions";
import { connect } from "react-redux";
import AddStaff from "./AddStaff";
import ReportAndStatistic from "./ReportAndStatistic";
import AddProject from "./AddProject/AddProject";
import AddSlider from "./AddSlider/AddSlider";
import AddProgramme from "./AddProgramme/AddPorgramme";
import AddNews from "./AddNews/AddNews";
import AddGallery from "./AddGallery/AddGallery";
import AddState from "./AddState/AddState";
import AddLGA from "./AddLGA/AddLGA";
import AddAssets from "./AddAssets/AddAssets";
import AddAssetType from "./AddAssetType/AddAssetType";
import Message from "./Message/Message";
import AddAddress from "./AddAddress/AddAddress";
import DynamicForm from "./DynamicForm";
import AddResources from "./AddResources/AddResources";
import AddJob from "./AddJob/AddJob";

function Routing(props) {
  return (
    <>
      <Routes>
        <Route path="/home" element={<Home />} />
        <Route path="/auth" element={<Authentication />} />
        <Route path="/projects" element={<AddProject />} />
        <Route path="/programme" element={<AddProgramme />} />
        <Route path="/news" element={<AddNews />} />
        <Route path="/staff" element={<AddStaff />} />
        <Route path="/states" element={<AddState />} />
        <Route path="/lga" element={<AddLGA />} />
        <Route path="/gallery" element={<AddGallery />} />
        <Route path="/slider" element={<AddSlider />} />
        <Route path="/report" element={<ReportAndStatistic />} />
        <Route path="/assets" element={<AddAssets />} />
        <Route path="/asset/type" element={<AddAssetType />} />
        <Route path="/message" element={<Message />} />
        <Route path="/address" element={<AddAddress />} />
        <Route path="/form" element={<DynamicForm />} />
        <Route path="/resources" element={<AddResources />} />
        <Route path="/jobs" element={<AddJob />} />
        <Route path="*" element={<Home />} />
      </Routes>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    HomePageData: state.HomePageContents,
    UserProfile: state.LoginDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setOnLoginDetails: (p) => {
      dispatch(setLoginDetails(p));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Routing);

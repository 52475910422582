import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { currencyConverter, formatDate, serverLink } from "../resources/Url";
import { showAlert } from "../pages/sweetalert/sweetalert";
import axios from "axios";
import { setLoginDetails } from "../actions/Actions";
import { connect } from "react-redux";
import NavBar from "./NavBar";
import Footer from "./Footer";
import { useReactToPrint } from "react-to-print";
import Header from "./Header";
import logo from "../resources/mibusiness-report.png";
import HomeContentLoader from "./HomeContentLoader/HomeContentLoader";
import arrayShuffle from "array-shuffle";

function ReportAndStatistic(props) {
  const [position, setPosition] = useState(0);
  const componentRef = useRef();
  const [isLoading, setIsLoading] = useState(true);
  const [grandTotal, setGrandTotal] = useState([]);
  const [completed, setCompleted] = useState([]);
  const [activeAsset, setActiveAsset] = useState([]);
  const [inActiveAsset, setInActiveAsset] = useState([]);
  const [total, setTotal] = useState([]);
  const [totalAsset, setTotalAsset] = useState([]);
  const [totalActiveAssetHec, setTotalActiveAssetHec] = useState([]);
  const [totalInActiveAssetHec, setTotalInActiveAssetHec] = useState([]);
  const [ongoing, setOngoing] = useState([]);
  const [canPrint, setCanPrint] = useState(false);
  const [printableData, setPrintableData] = useState([]);
  const [uniqueProjectsYear, setUniqueProjectsYear] = useState([]);
  const [createItem, setCreateItem] = useState({
    Date: "",
    ReportType: "",
    Mode: "",
    LocationID: "",
  });

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1; // Note: months are zero-indexed
  const currentDay = currentDate.getDate();

  const resetForm = () => {
    setCreateItem({
      ...createItem,
      Date: "",
      ReportType: "",
      Mode: "",
      LocationID: "",
    });
  };

  const navigate = useNavigate();
  const [currentUser] = useState(
    typeof props?.UserProfile !== "undefined" ? props?.UserProfile : []
  );

  const [staff] = useState(
    typeof props?.HomePageData.staff !== "undefined"
      ? props?.HomePageData.staff
      : []
  );

  const [projects] = useState(
    typeof props?.HomePageData.projects !== "undefined"
      ? props?.HomePageData.projects
      : []
  );

  useEffect(() => {
    // Assuming props?.HomePageData.projects is an array of objects with a 'year' property
    const projects = props?.HomePageData.projects || [];

    // Extract unique years
    const uniqueYears = [...new Set(projects.map((project) => project.Year))];

    // Set the unique years in state
    setUniqueProjectsYear(uniqueYears);
  }, [props?.HomePageData.projects]);

  console.log("uniqueProjectsYear", uniqueProjectsYear);

  const [programmes] = useState(
    typeof props?.HomePageData.programmes !== "undefined"
      ? arrayShuffle(props?.HomePageData.programmes)
      : []
  );

  const [asset] = useState(
    typeof props?.HomePageData.assets !== "undefined"
      ? arrayShuffle(props?.HomePageData.assets)
      : []
  );

  const [states] = useState(
    typeof props?.HomePageData.states !== "undefined"
      ? arrayShuffle(props?.HomePageData.states)
      : []
  );

  const [lga] = useState(
    typeof props?.HomePageData.lga !== "undefined"
      ? arrayShuffle(props?.HomePageData.lga)
      : []
  );

  const onEdit = (e) => {
    setCreateItem({
      ...createItem,
      [e.target.id]: e.target.value,
    });

    setPrintableData([]);
    setCanPrint(false);
  };

  const printReceipt = useReactToPrint({
    content: () => componentRef.current,
  });

  const generateReceipt = () => {
    printReceipt();
  };

  useEffect(() => {
    if (currentUser.length === 0) {
      navigate("/auth");
    }
    if (projects) {
      const timeoutId = setTimeout(() => {
        setPosition(1);
        setIsLoading(false);
      }, 500);
      return () => clearTimeout(timeoutId);
    }
  }, [currentUser]);

  useEffect(() => {
    window.onafterprint = () => {
      window.location.reload();
      setCanPrint(false);
    };
  }, []);

  const onSubmit = async (e) => {
    setTotal([]);
    setCompleted([]);
    setOngoing([]);
    setGrandTotal([]);
    setPrintableData([]);
    setCanPrint(false);
    e.preventDefault();
    setIsLoading(true);
    for (let key in createItem) {
      if (
        createItem.hasOwnProperty(key) &&
        key !== "LocationID" &&
        key !== "Date"
      ) {
        if (createItem[key] === "") {
          await showAlert("EMPTY FIELD", `Please enter ${key}`, "error");
          return false;
        }
      }
    }
    if (createItem.ReportType === "Project") {
      if (createItem.Mode === "Location") {
        await axios
          .get(`${serverLink}shared/get/projects`)
          .then((result) => {
            if (result.data.length > 0) {
              const selectedData = createItem.Date;
              const year = selectedData;

              const timeoutId = setTimeout(() => {
                setPosition(1);

                const filteredProjectReportArray = result.data.filter(
                  (item) =>
                    item.Year === year &&
                    item.StateId === Number(createItem.LocationID)
                );

                const totalCompleted = result.data.filter(
                  (item) =>
                    item.Year === year &&
                    item.StateId === Number(createItem.LocationID) &&
                    item.Status === "Completed"
                );

                const totalOngoing = result.data.filter(
                  (item) =>
                    item.Year === year &&
                    item.StateId === Number(createItem.LocationID) &&
                    item.Status === "Ongoing"
                );

                setPosition(1);
                setPrintableData(filteredProjectReportArray);
                setCompleted(totalCompleted.length);
                setOngoing(totalOngoing.length);
                setTotal(filteredProjectReportArray.length);
                setIsLoading(false);
                setCanPrint(true);
              }, 5000);

              return () => clearTimeout(timeoutId);
            }
          })
          .catch((err) => {
            console.log("NETWORK ERROR", err);
          });
      }
      if (createItem.Mode === "All") {
        await axios
          .get(`${serverLink}shared/get/projects`)
          .then((result) => {
            if (result.data.length > 0) {
              const timeoutId = setTimeout(() => {
                setPosition(1);

                const filteredProjectReportArray = result.data;

                const totalCompleted = result.data.filter(
                  (item) => item.Status === "Completed"
                );

                const totalOngoing = result.data.filter(
                  (item) => item.Status === "Ongoing"
                );

                setPosition(1);
                setPrintableData(filteredProjectReportArray);
                setCompleted(totalCompleted.length);
                setOngoing(totalOngoing.length);
                setTotal(filteredProjectReportArray.length);
                setIsLoading(false);
                setCanPrint(true);
              }, 5000);

              return () => clearTimeout(timeoutId);
            }
          })
          .catch((err) => {
            console.log("NETWORK ERROR", err);
          });
      }
      if (createItem.Mode === "Year") {
        await axios
          .get(`${serverLink}shared/get/projects`)
          .then((result) => {
            if (result.data.length > 0) {
              const selectedData = createItem.Date;
              const year = selectedData;

              const timeoutId = setTimeout(() => {
                setPosition(1);

                const filteredProjectReportArray = result.data.filter(
                  (item) => item.Year === year
                );

                const totalCompleted = result.data.filter(
                  (item) => item.Year === year && item.Status === "Completed"
                );

                const totalOngoing = result.data.filter(
                  (item) => item.Year === year && item.Status === "Ongoing"
                );

                setPosition(1);
                setPrintableData(filteredProjectReportArray);
                setCompleted(totalCompleted.length);
                setOngoing(totalOngoing.length);
                setTotal(filteredProjectReportArray.length);
                setIsLoading(false);
                setCanPrint(true);
              }, 5000);

              return () => clearTimeout(timeoutId);
            }
          })
          .catch((err) => {
            console.log("NETWORK ERROR", err);
          });
      }
    }

    // if (createItem.ReportType === "Asset") {
    //   if (createItem.Mode === "Location") {
    //     await axios
    //       .get(`${serverLink}shared/get/projects`)
    //       .then((result) => {
    //         if (result.data.length > 0) {
    //           const selectedData = createItem.Date;
    //           const year = selectedData.split("-")[0];
    //
    //           const timeoutId = setTimeout(() => {
    //             setPosition(1);
    //
    //             const filteredProjectReportArray = result.data.filter(
    //               (item) =>
    //                 item.Year === year &&
    //                 item.StateId === Number(createItem.LocationID)
    //             );
    //
    //             const totalCompleted = result.data.filter(
    //               (item) =>
    //                 item.Year === year &&
    //                 item.StateId === Number(createItem.LocationID) &&
    //                 item.Status === "Completed"
    //             );
    //
    //             const totalOngoing = result.data.filter(
    //               (item) =>
    //                 item.Year === year &&
    //                 item.StateId === Number(createItem.LocationID) &&
    //                 item.Status === "Ongoing"
    //             );
    //
    //             const total = result.data.filter(
    //               (item) =>
    //                 item.Year === year &&
    //                 item.StateId === Number(createItem.LocationID)
    //             );
    //
    //             setPosition(1);
    //             setPrintableData(filteredProjectReportArray);
    //             setGrandTotal(filteredProjectReportArray.length);
    //             setCompleted(totalCompleted.length);
    //             setOngoing(totalOngoing.length);
    //             setTotal(total.length);
    //             setIsLoading(false);
    //             setCanPrint(true);
    //           }, 5000);
    //
    //           return () => clearTimeout(timeoutId);
    //         }
    //       })
    //       .catch((err) => {
    //         console.log("NETWORK ERROR", err);
    //       });
    //   }
    //   if (createItem.Mode === "All") {
    //     await axios
    //       .get(`${serverLink}shared/get/assets`)
    //       .then((result) => {
    //         if (result.data.length > 0) {
    //           const timeoutId = setTimeout(() => {
    //             setPosition(1);
    //
    //             const filteredProjectReportArray = result.data;
    //
    //             const activeAsset = result.data.filter(
    //               (item) => item.Status === Number(1)
    //             );
    //
    //             const inActiveAsset = result.data.filter(
    //               (item) => item.Status === Number(0)
    //             );
    //
    //             const totalActiveAsset = activeAsset.reduce(
    //               (a, r) => (a = a + r.QuantityDescription),
    //               0
    //             );
    //
    //             const columnSum = activeAsset.reduce(
    //               (accumulator, currentItem) => {
    //                 return accumulator + currentItem.QuantityDescription;
    //               },
    //               0
    //             );
    //
    //             // const totalInActiveAsset = inActiveAsset.reduce(
    //             //   (a, r) => (a = a + r.QuantityDescription),
    //             //   0
    //             // );
    //             //
    //             // const totalLand = result.data.reduce(
    //             //   (a, r) => (a = a + r.QuantityDescription),
    //             //   0
    //             // );
    //
    //             console.log("result", result);
    //
    //             // setPosition(1);
    //             // setPrintableData(filteredProjectReportArray);
    //             // setActiveAsset(activeAsset);
    //             // setInActiveAsset(inActiveAsset);
    //             // setTotal(totalLand);
    //             // setTotalActiveAssetHec(totalActiveAsset);
    //             // setTotalInActiveAssetHec(totalInActiveAsset);
    //
    //             setIsLoading(false);
    //             setCanPrint(true);
    //           }, 5000);
    //
    //           return () => clearTimeout(timeoutId);
    //         }
    //       })
    //       .catch((err) => {
    //         console.log("NETWORK ERROR", err);
    //       });
    //   }
    //   if (createItem.Mode === "Year") {
    //     await axios
    //       .get(`${serverLink}shared/get/projects`)
    //       .then((result) => {
    //         if (result.data.length > 0) {
    //           const selectedData = createItem.Date;
    //           const year = selectedData.split("-")[0];
    //
    //           const timeoutId = setTimeout(() => {
    //             setPosition(1);
    //
    //             const filteredProjectReportArray = result.data.filter(
    //               (item) => item.Year === year
    //             );
    //
    //             const totalCompleted = result.data.filter(
    //               (item) => item.Year === year && item.Status === "Completed"
    //             );
    //
    //             const totalOngoing = result.data.filter(
    //               (item) =>
    //                 item.Year === year &&
    //                 item.StateId === Number(createItem.LocationID) &&
    //                 item.Status === "Ongoing"
    //             );
    //
    //             const total = result.data.filter(
    //               (item) =>
    //                 item.Year === year &&
    //                 item.StateId === Number(createItem.LocationID)
    //             );
    //
    //             setPosition(1);
    //             setPrintableData(filteredProjectReportArray);
    //             setGrandTotal(filteredProjectReportArray.length);
    //             setCompleted(totalCompleted.length);
    //             setOngoing(totalOngoing.length);
    //             setTotal(total.length);
    //             setIsLoading(false);
    //             setCanPrint(true);
    //           }, 5000);
    //
    //           return () => clearTimeout(timeoutId);
    //         }
    //       })
    //       .catch((err) => {
    //         console.log("NETWORK ERROR", err);
    //       });
    //   }
    // }

    const timeoutId = setTimeout(() => {
      setPosition(1);
      setIsLoading(false);
    }, 5000);
    return () => clearTimeout(timeoutId);
  };

  return (
    <>
      {isLoading ? (
        <HomeContentLoader />
      ) : (
        <>
          <div id="wrapper">
            <Header />
            <NavBar />
            <div id="page-wrapper">
              <div className="header">
                <h1 className="page-header">Report</h1>
              </div>
              <div id="page-inner">
                <div className="row">
                  <div className="col-md-12">
                    <div className="panel panel-default">
                      <div className="panel-heading"></div>

                      <div className="panel-body">
                        <div className="col-md-12">
                          <div className="modal-body">
                            <div className="col-lg-6">
                              <div className="form-group">
                                <select
                                  id="ReportType"
                                  className="form-control"
                                  value={createItem.ReportType}
                                  onChange={onEdit}
                                >
                                  <option value="">Select Report</option>
                                  {/*<option value="Asset">Asset</option>*/}
                                  <option value="Project">Project</option>
                                  {/*<option value="Programme">Programme</option>*/}
                                </select>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="form-group">
                                <select
                                  id="Mode"
                                  className="form-control"
                                  value={createItem.Mode}
                                  onChange={onEdit}
                                  disabled={
                                    createItem.ReportType === "" && true
                                  }
                                >
                                  <option value="">Select Mode</option>
                                  <option value="Location">Location</option>
                                  <option value="Year">Year</option>
                                  <option value="All">All</option>
                                </select>
                              </div>
                            </div>

                            {createItem.ReportType === "Project" && (
                              <>
                                {createItem.Mode === "Location" && (
                                  <>
                                    <div className="col-lg-6">
                                      <div className="form-group">
                                        <select
                                          className="form-control"
                                          id="LocationID"
                                          name="LocationID"
                                          onChange={onEdit}
                                          value={createItem.LocationID}
                                          disabled={
                                            createItem.Mode === "" && true
                                          }
                                        >
                                          <option value="">
                                            Select Location
                                          </option>
                                          {states.length > 0 &&
                                            states.map((item, index) => {
                                              return (
                                                <option
                                                  key={item.StateId}
                                                  value={item.StateId}
                                                >
                                                  {item.Title}
                                                </option>
                                              );
                                            })}
                                        </select>
                                      </div>
                                    </div>

                                    <div
                                      className={
                                        createItem.Mode === "Location"
                                          ? "col-lg-6"
                                          : "col-lg-12"
                                      }
                                    >
                                      {/*<div className="col-lg-12">*/}
                                      <div className="form-group">
                                        <select
                                          className="form-control"
                                          id="Date"
                                          name="Date"
                                          onChange={onEdit}
                                          value={createItem.Date}
                                          disabled={
                                            createItem.LocationID === "" &&
                                            createItem.Mode !== "All" &&
                                            true
                                          }
                                        >
                                          <option value="">Select Year</option>
                                          {uniqueProjectsYear.length > 0 &&
                                            uniqueProjectsYear.map(
                                              (item, index) => {
                                                return (
                                                  <option
                                                    key={index}
                                                    value={item}
                                                  >
                                                    {item}
                                                  </option>
                                                );
                                              }
                                            )}
                                        </select>
                                      </div>
                                    </div>

                                    <div className="col-lg-12">
                                      {createItem.Date !== "" &&
                                        createItem.Mode !== "" &&
                                        createItem.ReportType !== "" &&
                                        printableData &&
                                        Object.keys(printableData).length ===
                                          0 && (
                                          <button
                                            type="button"
                                            className="btn btn-primary col-lg-12"
                                            onClick={onSubmit}
                                          >
                                            Generate Report
                                          </button>
                                        )}
                                    </div>
                                  </>
                                )}

                                {createItem.Mode === "Year" && (
                                  <>
                                    <div className="col-lg-12">
                                      <div className="form-group">
                                        <select
                                          className="form-control"
                                          id="Date"
                                          name="Date"
                                          onChange={onEdit}
                                          value={createItem.Date}
                                        >
                                          <option value="">Select Year</option>
                                          {uniqueProjectsYear.length > 0 &&
                                            uniqueProjectsYear.map(
                                              (item, index) => {
                                                return (
                                                  <option
                                                    key={index}
                                                    value={item}
                                                  >
                                                    {item}
                                                  </option>
                                                );
                                              }
                                            )}
                                        </select>
                                      </div>
                                    </div>

                                    <div className="col-lg-12">
                                      {createItem.Date !== "" &&
                                        createItem.Mode !== "" &&
                                        createItem.ReportType !== "" &&
                                        printableData &&
                                        Object.keys(printableData).length ===
                                          0 && (
                                          <button
                                            type="button"
                                            className="btn btn-primary col-lg-12"
                                            onClick={onSubmit}
                                          >
                                            Generate Report
                                          </button>
                                        )}
                                    </div>
                                  </>
                                )}

                                <div className="col-lg-12">
                                  {createItem.Mode === "All" &&
                                    Object.keys(printableData).length === 0 && (
                                      <button
                                        type="button"
                                        className="btn btn-primary col-lg-12"
                                        onClick={onSubmit}
                                      >
                                        Generate Report
                                      </button>
                                    )}
                                </div>
                              </>
                            )}

                            {createItem.ReportType === "Asset" && (
                              <>
                                {createItem.Mode === "Location" && (
                                  <>
                                    <div className="col-lg-6">
                                      <div className="form-group">
                                        <select
                                          className="form-control"
                                          id="LocationID"
                                          name="LocationID"
                                          onChange={onEdit}
                                          value={createItem.LocationID}
                                          disabled={
                                            createItem.Mode === "" && true
                                          }
                                        >
                                          <option value="">
                                            Select Location
                                          </option>
                                          {states.length > 0 &&
                                            states.map((item, index) => {
                                              return (
                                                <option
                                                  key={item.StateId}
                                                  value={item.StateId}
                                                >
                                                  {item.Title}
                                                </option>
                                              );
                                            })}
                                        </select>
                                      </div>
                                    </div>

                                    <div
                                      className={
                                        createItem.Mode === "Location"
                                          ? "col-lg-6"
                                          : "col-lg-12"
                                      }
                                    >
                                      <div className="form-group">
                                        <input
                                          type="date"
                                          id="Date"
                                          className="form-control"
                                          placeholder="Date"
                                          value={formatDate(createItem.Date)}
                                          onChange={onEdit}
                                          disabled={
                                            createItem.LocationID === "" &&
                                            createItem.Mode !== "All" &&
                                            true
                                          }
                                        />
                                      </div>
                                    </div>

                                    <div className="col-lg-12">
                                      {createItem.Date !== "" &&
                                        createItem.Mode !== "" &&
                                        createItem.ReportType !== "" &&
                                        printableData &&
                                        Object.keys(printableData).length ===
                                          0 && (
                                          <button
                                            type="button"
                                            className="btn btn-primary col-lg-12"
                                            onClick={onSubmit}
                                          >
                                            Generate Report
                                          </button>
                                        )}
                                    </div>
                                  </>
                                )}

                                {createItem.Mode === "Year" && (
                                  <>
                                    <div className={"col-lg-12"}>
                                      <div className="form-group">
                                        <input
                                          type="date"
                                          id="Date"
                                          className="form-control"
                                          placeholder="Date"
                                          value={formatDate(createItem.Date)}
                                          onChange={onEdit}
                                        />
                                      </div>
                                    </div>

                                    <div className="col-lg-12">
                                      {createItem.Date !== "" &&
                                        createItem.Mode !== "" &&
                                        createItem.ReportType !== "" &&
                                        printableData &&
                                        Object.keys(printableData).length ===
                                          0 && (
                                          <button
                                            type="button"
                                            className="btn btn-primary col-lg-12"
                                            onClick={onSubmit}
                                          >
                                            Generate Report
                                          </button>
                                        )}
                                    </div>
                                  </>
                                )}

                                <div className="col-lg-12">
                                  {createItem.Mode === "All" &&
                                    Object.keys(printableData).length === 0 && (
                                      <button
                                        type="button"
                                        className="btn btn-primary col-lg-12"
                                        onClick={onSubmit}
                                      >
                                        Generate Report
                                      </button>
                                    )}
                                </div>
                              </>
                            )}

                            <div className="col-lg-12">
                              {printableData &&
                                Object.keys(printableData).length > 0 && (
                                  <button
                                    type="button"
                                    className="btn btn-primary col-lg-12"
                                    onClick={generateReceipt}
                                  >
                                    Print Report
                                  </button>
                                )}
                            </div>

                            {/*PRINT PROJECT REPORT*/}
                            {createItem.ReportType === "Project" && (
                              <>
                                <div className="col-lg-12">
                                  <br />
                                  {canPrint &&
                                  Object.keys(printableData).length > 0 ? (
                                    <div
                                      ref={componentRef}
                                      style={{
                                        // display: "flex",
                                        alignItems: "center",
                                        marginLeft: "20px",
                                        marginRight: "20px",
                                        justifyContent: "center",
                                        width: "8.27in",
                                        height: "11.69in",
                                      }}
                                    >
                                      <div>
                                        <div>
                                          <div>
                                            {/*Advanced Tables */}
                                            <div>
                                              <div
                                                className="panel"
                                                style={{
                                                  backgroundColor: "#dedede",
                                                }}
                                              >
                                                <h4
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    marginBottom: "0",
                                                  }}
                                                >
                                                  <img
                                                    src={logo}
                                                    alt="Logo"
                                                    style={{
                                                      height: "140px",
                                                      marginRight: "10px",
                                                      marginBottom: "0",
                                                    }}
                                                  />
                                                </h4>
                                                <h3
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    marginTop: "0",
                                                  }}
                                                >
                                                  {createItem.LocationID !==
                                                    "" &&
                                                    states.length > 0 &&
                                                    states
                                                      .filter(
                                                        (i) =>
                                                          i.StateId ===
                                                          Number(
                                                            createItem.LocationID
                                                          )
                                                      )
                                                      .map((r) => r.Title)}{" "}
                                                  NALDA {createItem.ReportType}{" "}
                                                  Report{" "}
                                                  {createItem.Mode !== "All" &&
                                                    createItem.Date.split(
                                                      "-"
                                                    )[0]}
                                                </h3>
                                                {/*<h6*/}
                                                {/*  style={{*/}
                                                {/*    display: "flex",*/}
                                                {/*    alignItems: "center",*/}
                                                {/*    justifyContent: "center",*/}
                                                {/*  }}*/}
                                                {/*>*/}
                                                {/*  Generated By:{" "}*/}
                                                {/*  {currentUser.FullName}*/}
                                                {/*</h6>*/}
                                                <h6
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                  }}
                                                >
                                                  Generated Date:{" "}
                                                  {`${currentMonth}/${currentDay}/${currentYear}`}
                                                </h6>
                                              </div>
                                              <div className="panel-body">
                                                <div className="table-responsive">
                                                  <table
                                                    className="table table-striped table-bordered table-hover"
                                                    id="dataTables-example"
                                                  >
                                                    <thead>
                                                      <tr>
                                                        <th>S/No</th>
                                                        <th>Title</th>
                                                        <th>LGA</th>
                                                        <th>Project Status</th>
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                      {printableData?.length >
                                                        0 &&
                                                        printableData.map(
                                                          (item, index) => {
                                                            return (
                                                              <tr
                                                                className="gradeC"
                                                                key={index}
                                                              >
                                                                <td>
                                                                  {index + 1}
                                                                </td>
                                                                <td>
                                                                  {item.Title}
                                                                </td>
                                                                <td>
                                                                  {lga.length >
                                                                    0 &&
                                                                    lga
                                                                      .filter(
                                                                        (i) =>
                                                                          i.LGAId ===
                                                                          Number(
                                                                            item.LGAId
                                                                          )
                                                                      )
                                                                      .map(
                                                                        (r) =>
                                                                          r.Title
                                                                      )}
                                                                </td>
                                                                <td>
                                                                  {item.Status}
                                                                </td>
                                                              </tr>
                                                            );
                                                          }
                                                        )}
                                                      <>
                                                        <tr className="gradeC">
                                                          <td></td>
                                                          <td>
                                                            <strong>
                                                              Completed Project
                                                            </strong>
                                                          </td>
                                                          <td>
                                                            <strong>
                                                              {completed}
                                                            </strong>
                                                          </td>
                                                        </tr>
                                                        <tr className="gradeC">
                                                          <td></td>
                                                          <td>
                                                            <strong>
                                                              Ongoing Project
                                                            </strong>
                                                          </td>
                                                          <td>
                                                            <strong>
                                                              {ongoing}
                                                            </strong>
                                                          </td>
                                                        </tr>

                                                        <tr className="gradeC">
                                                          <td></td>
                                                          <td>
                                                            <strong>
                                                              Total Projects
                                                            </strong>
                                                          </td>
                                                          <td>
                                                            <strong>
                                                              {total}
                                                            </strong>
                                                          </td>
                                                        </tr>
                                                      </>
                                                    </tbody>
                                                  </table>
                                                </div>
                                              </div>
                                            </div>
                                            {/*End Advanced Tables*/}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <>
                                      {canPrint && (
                                        <div className="panel-body">
                                          <div className="alert alert-info">
                                            <strong>Sorry!</strong> There is no
                                            data for the selected report.
                                          </div>
                                        </div>
                                      )}
                                    </>
                                  )}
                                </div>
                              </>
                            )}

                            {/*PRINT ASSET REPORT*/}
                            {createItem.ReportType === "Asset" && (
                              <>
                                <div className="col-lg-12">
                                  <br />
                                  {canPrint &&
                                  Object.keys(printableData).length > 0 ? (
                                    <div
                                      ref={componentRef}
                                      style={{
                                        // display: "flex",
                                        alignItems: "center",
                                        marginLeft: "20px",
                                        marginRight: "20px",
                                        justifyContent: "center",
                                        width: "8.27in",
                                        height: "11.69in",
                                      }}
                                    >
                                      <div>
                                        <div>
                                          <div>
                                            {/*Advanced Tables */}
                                            <div>
                                              <div
                                                className="panel"
                                                style={{
                                                  backgroundColor: "#dedede",
                                                }}
                                              >
                                                <h4
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    marginBottom: "0",
                                                  }}
                                                >
                                                  <img
                                                    src={logo}
                                                    alt="Logo"
                                                    style={{
                                                      height: "140px",
                                                      marginRight: "10px",
                                                      marginBottom: "0",
                                                    }}
                                                  />
                                                </h4>
                                                <h3
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    marginTop: "0",
                                                  }}
                                                >
                                                  {createItem.LocationID !==
                                                    "" &&
                                                    states.length > 0 &&
                                                    states
                                                      .filter(
                                                        (i) =>
                                                          i.StateId ===
                                                          Number(
                                                            createItem.LocationID
                                                          )
                                                      )
                                                      .map((r) => r.Title)}{" "}
                                                  NALDA {createItem.ReportType}{" "}
                                                  Report{" "}
                                                  {createItem.Mode !== "All" &&
                                                    createItem.Date.split(
                                                      "-"
                                                    )[0]}
                                                </h3>
                                                <h6
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                  }}
                                                >
                                                  Generated By:{" "}
                                                  {currentUser.FullName}
                                                </h6>
                                                <h6
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                  }}
                                                >
                                                  Date:{" "}
                                                  {`${currentMonth}/${currentDay}/${currentYear}`}
                                                </h6>
                                              </div>
                                              <div className="panel-body">
                                                <div className="table-responsive">
                                                  <table
                                                    className="table table-striped table-bordered table-hover"
                                                    id="dataTables-example"
                                                  >
                                                    <thead>
                                                      <tr>
                                                        <th>S/No</th>
                                                        <th>Title</th>
                                                        <th>LGA</th>
                                                        <th>Hectare</th>
                                                        <th>Status</th>
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                      {printableData?.length >
                                                        0 &&
                                                        printableData.map(
                                                          (item, index) => {
                                                            return (
                                                              <tr
                                                                className="gradeC"
                                                                key={index}
                                                              >
                                                                <td>
                                                                  {index + 1}
                                                                </td>
                                                                <td>
                                                                  {item.Title}
                                                                </td>
                                                                <td>
                                                                  {lga.length >
                                                                    0 &&
                                                                    lga
                                                                      .filter(
                                                                        (i) =>
                                                                          i.LGAId ===
                                                                          Number(
                                                                            item.LGAId
                                                                          )
                                                                      )
                                                                      .map(
                                                                        (r) =>
                                                                          r.Title
                                                                      )}
                                                                </td>
                                                                <td>
                                                                  {
                                                                    item.QuantityDescription
                                                                  }
                                                                </td>
                                                                <td>
                                                                  {item.Status ===
                                                                  Number(1)
                                                                    ? "Active"
                                                                    : "In-Active"}
                                                                </td>
                                                              </tr>
                                                            );
                                                          }
                                                        )}
                                                      <>
                                                        <tr className="gradeC">
                                                          <td></td>
                                                          <td>
                                                            <strong>
                                                              Active Asset
                                                            </strong>
                                                          </td>
                                                          <td>
                                                            <strong>
                                                              {activeAsset}
                                                            </strong>
                                                          </td>
                                                        </tr>
                                                        <tr className="gradeC">
                                                          <td></td>
                                                          <td>
                                                            <strong>
                                                              InActive Asset
                                                            </strong>
                                                          </td>
                                                          <td>
                                                            <strong>
                                                              {inActiveAsset}
                                                            </strong>
                                                          </td>
                                                        </tr>

                                                        <tr className="gradeC">
                                                          <td></td>
                                                          <td>
                                                            <strong>
                                                              Total Asset
                                                            </strong>
                                                          </td>
                                                          <td>
                                                            <strong>
                                                              {total}
                                                            </strong>
                                                          </td>
                                                        </tr>
                                                      </>
                                                    </tbody>
                                                  </table>
                                                </div>
                                              </div>
                                            </div>
                                            {/*End Advanced Tables*/}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <>
                                      {canPrint && (
                                        <div className="panel-body">
                                          <div className="alert alert-info">
                                            <strong>Sorry!</strong> There is no
                                            data for the selected report.
                                          </div>
                                        </div>
                                      )}
                                    </>
                                  )}
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Footer />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    HomePageData: state.HomePageContents,
    UserProfile: state.LoginDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setOnLoginDetails: (p) => {
      dispatch(setLoginDetails(p));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportAndStatistic);

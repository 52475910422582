import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { serverLink } from "../../resources/Url";
import { showAlert } from "../../pages/sweetalert/sweetalert";
import axios from "axios";
import { setLoginDetails } from "../../actions/Actions";
import { connect } from "react-redux";
import NavBar from "../NavBar";
import Footer from "../Footer";
import Table from "../../pages/table/table";
import Header from "../Header";
import HomeContentLoader from "../HomeContentLoader/HomeContentLoader";
import SubmittingLoader from "../SubmittingLoader/SubmittingLoader";

function AddGallery(props) {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;
  const currentDay = currentDate.getDate();
  const navigate = useNavigate();
  const [currentUser] = useState(
    typeof props?.UserProfile !== "undefined" ? props?.UserProfile : []
  );
  const nALDALoginHistory =
    JSON.parse(localStorage.getItem("NALDALoginHistory")) || [];
  const [position, setPosition] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedFile1, setSelectedFile1] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);
  const [slider2, setSlider2] = useState(null);
  const [slider3, setSlider3] = useState(null);
  const [slider4, setSlider4] = useState(null);
  const [slider5, setSlider5] = useState(null);

  const [projects] = useState(
    typeof props?.HomePageData.projects !== "undefined"
      ? props?.HomePageData.projects
      : []
  );

  const [createItem, setCreateItem] = useState({
    GalleryId: "",
    Title: "",
    ParagraphOne: "",
    ParagraphTwo: "",
    Date: "",
    VideoUrlOne: "",
    VideoUrlTwo: "",
    VideoUrlThree: "",
    Location: "",
    ProjectUrl: "",
    FrontImage: "",
    MainImage: "",
    ProjectId: "",
    IsActive: "",
    Slider2: "",
    Slider3: "",
    Slider4: "",
    Slider5: "",
  });

  const resetItem = () => {
    setCreateItem({
      ...createItem,
      GalleryId: "",
      Title: "",
      ParagraphOne: "",
      ParagraphTwo: "",
      Date: "",
      VideoUrlOne: "",
      VideoUrlTwo: "",
      VideoUrlThree: "",
      Location: "",
      ProjectUrl: "",
      FrontImage: "",
      MainImage: "",
      ProjectId: "",
      IsActive: "",
      Slider2: "",
      Slider3: "",
      Slider4: "",
      Slider5: "",
    });
  };

  const [datatable, setDatatable] = useState({
    columns: [
      {
        label: "S/N",
        field: "sn",
      },
      {
        label: "Title",
        field: "Title",
      },
      {
        label: "ParagraphOne",
        field: "ParagraphOne",
      },
      {
        label: "Action",
        field: "action",
      },
    ],
    rows: [],
  });

  const onEdit = async (e) => {
    const id = e.target.id;
    const value = e.target.value;

    if (e.target.type === "file") {
      if (id === "image1") {
        setSelectedFile1(e.target.files[0]);
      }
      if (id === "image2") {
        setSelectedFile2(e.target.files[0]);
      }
      if (id === "image3") {
        setSlider2(e.target.files[0]);
      }
      if (id === "image4") {
        setSlider3(e.target.files[0]);
      }
      if (id === "image5") {
        setSlider4(e.target.files[0]);
      }
      if (id === "image6") {
        setSlider5(e.target.files[0]);
      }
    } else {
      setCreateItem({
        ...createItem,
        [id]: value,
      });
    }
  };

  const getProjects = async () => {
    await axios
      .get(`${serverLink}shared/get/gallery`)
      .then((result) => {
        if (result.data.length > 0) {
          let rows = [];
          result.data.map((staff, index) => {
            rows.push({
              sn: index + 1,
              GalleryId: staff.GalleryId,
              Title: staff.Title,
              ParagraphOne: staff.ParagraphOne,
              ParagraphTwo: staff.ParagraphTwo,
              Date: staff.Date,
              VideoUrlOne: staff.VideoUrlOne,
              VideoUrlTwo: staff.VideoUrlTwo,
              VideoUrlThree: staff.VideoUrlThree,
              Location: staff.Location,
              ProjectUrl: staff.ProjectUrl,
              FrontImage: staff.FrontImage,
              MainImage: staff.MainImage,
              ProjectId: staff.ProjectId,
              IsActive: staff.IsActive,
              action: (
                <button
                  type="button"
                  className="btn btn-primary"
                  style={{ backgroundColor: "#008435" }}
                  data-toggle="modal"
                  data-target="#myModal"
                  onClick={() =>
                    setCreateItem({
                      GalleryId: staff.GalleryId,
                      Title: staff.Title,
                      ParagraphOne: staff.ParagraphOne,
                      ParagraphTwo: staff.ParagraphTwo,
                      Date: staff.Date,
                      VideoUrlOne: staff.VideoUrlOne,
                      VideoUrlTwo: staff.VideoUrlTwo,
                      VideoUrlThree: staff.VideoUrlThree,
                      Location: staff.Location,
                      ProjectUrl: staff.ProjectUrl,
                      FrontImage: staff.FrontImage,
                      MainImage: staff.MainImage,
                      ProjectId: staff.ProjectId,
                      IsActive: staff.IsActive,
                    })
                  }
                >
                  Edit
                </button>
              ),
            });
          });

          setDatatable({
            ...datatable,
            columns: datatable.columns,
            rows: rows,
          });
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("NETWORK ERROR");
      });
  };

  useEffect(() => {
    getProjects().then((r) => {});
    const timeoutId = setTimeout(() => {
      setPosition(1);
      setIsLoading(false);
      if (currentUser.length === 0) {
        navigate("/auth");
      }
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [currentUser]);

  const uploadImage = async (formData, endpoint) => {
    try {
      const response = await axios.post(`${serverLink}${endpoint}`, formData);
      if (response.data.type === "success") {
        return response.data.file.url;
      }
      throw new Error("Image upload failed.");
    } catch (error) {
      console.error("Image upload error:", error);
      throw error;
    }
  };

  const submitForm = async () => {
    try {
      // Check form fields
      for (let key in createItem) {
        if (
          createItem.hasOwnProperty(key) &&
          key !== "GalleryId" &&
          key !== "ParagraphOne" &&
          key !== "FrontImage" &&
          key !== "MainImage" &&
          key !== "Slider2" &&
          key !== "Slider3" &&
          key !== "Slider4" &&
          key !== "VideoUrlOne" &&
          key !== "VideoUrlTwo" &&
          key !== "VideoUrlThree" &&
          key !== "Slider5" &&
          key !== "ProjectUrl" &&
          key !== "Date"
        ) {
          if (createItem[key] === "") {
            await showAlert("EMPTY FIELD", `Please enter ${key}`, "error");
            setIsSubmittingForm(false);
            return;
          }
        }
      }

      // Check image sizes
      const maxSizeKB = 2048;
      const imageChecks = [
        createItem.FrontImage,
        createItem.MainImage,
        createItem.Slider2,
        createItem.Slider3,
        createItem.Slider4,
        createItem.Slider5,
      ];

      for (const image of imageChecks) {
        if (image && image.size / 1024 > maxSizeKB) {
          await showAlert("", `Image Size Can't be more than 2MB`, "error");
          setIsSubmittingForm(false);
          return;
        }
      }

      const image1 = new FormData();
      image1.append("image1", selectedFile1);

      const image2 = new FormData();
      image2.append("image2", selectedFile2);

      const image3 = new FormData();
      image3.append("image3", slider2);

      const image4 = new FormData();
      image4.append("image4", slider3);

      const image5 = new FormData();
      image5.append("image5", slider4);

      const image6 = new FormData();
      image6.append("image6", slider5);

      // Upload images
      const image1_ = await uploadImage(image1, "gallery/upload/image1");
      const image2_ = await uploadImage(image2, "gallery/upload/image2");

      const image3_ =
        slider2 &&
        slider2.size > 0 &&
        (await uploadImage(image3, "gallery/upload/image3"));
      const image4_ =
        slider3 &&
        slider3.size > 0 &&
        (await uploadImage(image4, "gallery/upload/image4"));
      const image5_ =
        slider4 &&
        slider4.size > 0 &&
        (await uploadImage(image5, "gallery/upload/image5"));
      const image6_ =
        slider5 &&
        slider5.size > 0 &&
        (await uploadImage(image6, "gallery/upload/image6"));

      // Construct the sendData object
      const sendData = {
        GalleryId: createItem.GalleryId,
        Title: createItem.Title,
        ParagraphOne: createItem.ParagraphOne,
        ParagraphTwo: createItem.ParagraphTwo,
        Date: `${currentMonth}/${currentDay}/${currentYear}`,
        VideoUrlOne: createItem.VideoUrlOne,
        VideoUrlTwo: createItem.VideoUrlTwo,
        VideoUrlThree: createItem.VideoUrlThree,
        Location: createItem.Location,
        ProjectUrl: createItem.ProjectUrl,
        FrontImage: image1_ ? image1_ : null,
        MainImage: image2_ ? image2_ : null,
        ProjectId: createItem.ProjectId,
        IsActive: createItem.IsActive,
      };

      // Handle adding or updating the gallery
      if (createItem.GalleryId === "") {
        const response = await axios.post(`${serverLink}gallery/add`, sendData);
        if (response.data.message === "success") {
          // Gallery added successfully
          //Handle slider image upload and other actions ...

          const sliderImages = {
            Slider2Image: image3_ ? image3_ : null,
            Slider3Image: image4_ ? image4_ : null,
            Slider4Image: image5_ ? image5_ : null,
            Slider5Image: image6_ ? image6_ : null,
            VideoUrlOne: createItem.VideoUrlOne,
            VideoUrlTwo: createItem.VideoUrlTwo,
            VideoUrlThree: createItem.VideoUrlThree,
          };

          const sendData_ = {
            GalleryId: response.data.galleryId,
            Title: createItem.Title,
            Sliders: Object.values(sliderImages),
          };

          fetch(`${serverLink}gallery/video/add`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(sendData_),
          })
            .then((response) => response.json())
            .then((data) => {
              if (data.message === "success") {
                // ON SUCCESS
              }
            })
            .catch((error) => {
              console.error("Error:", error);
            });

          if (slider2 && slider2.size > 0) {
            fetch(`${serverLink}gallery/slider/add`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(sendData_),
            })
              .then((response) => response.json())
              .then((data) => {
                if (data.message === "success") {
                  // ON SUCCESS
                }
              })
              .catch((error) => {
                console.error("Error:", error);
              });
          }
          await resetItem();
          await getProjects();
          await showAlert("Success", `Gallery Added Successfully`, "success");
          setIsSubmittingForm(false);
        } else if (response.data.message === "exist") {
          await showAlert(
            "Error",
            `Gallery with given details already exists!`,
            "error"
          );
          setIsSubmittingForm(false);
        }
      } else {
        const response = await axios.patch(
          `${serverLink}gallery/update`,
          sendData
        );
        if (response.data.message === "success") {
          resetItem();
          await getProjects();
          await showAlert("Success", "Gallery Updated Successfully", "success");
          setIsSubmittingForm(false);
        } else {
          await showAlert(
            "ERROR",
            "Something went wrong. Please try again!",
            "error"
          );
          setIsSubmittingForm(false);
        }
      }
    } catch (error) {
      // Handle errors here, show alerts, etc.
      console.error("Form submission error:", error);
      setIsSubmittingForm(false);
    }
  };

  // Call the submitForm function when the form is submitted
  const onSubmit = async (e) => {
    setIsSubmittingForm(true);
    e.preventDefault();
    await submitForm();
  };

  return (
    <>
      {isLoading ? (
        <HomeContentLoader />
      ) : (
        <div id="wrapper">
          <Header />
          <NavBar />
          <div id="page-wrapper">
            <div class="header">
              <h1 class="page-header">Manage Gallery</h1>
            </div>
            <div id="page-inner">
              <div class="row">
                <div class="col-md-12">
                  <div class="panel panel-default">
                    <div class="panel-heading"></div>

                    <div class="panel-body">
                      <div className="panel-body">
                        <button
                          style={{
                            backgroundColor: "#008435",
                            float: "right",
                          }}
                          className="btn btn-primary"
                          data-toggle="modal"
                          data-target="#myModal"
                          onClick={resetItem}
                        >
                          Add Gallery
                        </button>
                        {isSubmittingForm && <SubmittingLoader />}
                        <div
                          className="modal fade"
                          id="myModal"
                          tabIndex="-1"
                          role="dialog"
                          aria-labelledby="myModalLabel"
                          aria-hidden="true"
                        >
                          <div className="modal-dialog">
                            <div
                              className="modal-content"
                              style={{
                                width: "700px",
                              }}
                            >
                              <div className="modal-header">
                                <button
                                  type="button"
                                  className="close"
                                  data-dismiss="modal"
                                  aria-hidden="true"
                                >
                                  &times;
                                </button>
                                <h4 className="modal-title" id="myModalLabel">
                                  Gallery Component
                                </h4>
                              </div>
                              <div className="modal-body">
                                <div className="col-lg-6 mb-4">
                                  <div className="form-group">
                                    <input
                                      className="form-control"
                                      id="Title"
                                      type="text"
                                      placeholder="Title"
                                      value={createItem.Title}
                                      onChange={onEdit}
                                    />
                                  </div>
                                </div>

                                <div className="col-lg-6 mb-4">
                                  <div className="form-group">
                                    <input
                                      className="form-control"
                                      id="Location"
                                      type="text"
                                      placeholder="Location"
                                      value={createItem.Location}
                                      onChange={onEdit}
                                    />
                                  </div>
                                </div>

                                <div className="col-lg-6 mb-4">
                                  <div className="form-group">
                                    <select
                                      className="form-control"
                                      id="ProjectId"
                                      name="ProjectId"
                                      onChange={onEdit}
                                      value={createItem.ProjectId}
                                    >
                                      <option value="">Select Project</option>
                                      {projects.length > 0 &&
                                        projects.map((item, index) => {
                                          return (
                                            <option
                                              key={item.ProjectId}
                                              value={item.ProjectId}
                                            >
                                              {item.Title}
                                            </option>
                                          );
                                        })}
                                    </select>
                                  </div>
                                </div>
                                <div className="col-lg-6 ">
                                  <div className="form-group">
                                    <select
                                      id="IsActive"
                                      className="form-control"
                                      value={createItem.IsActive}
                                      onChange={onEdit}
                                    >
                                      <option value="">Select Status</option>
                                      <option value="1">Active</option>
                                      <option value="0">InActive</option>
                                    </select>
                                  </div>
                                </div>

                                <div className="col-lg-6 mb-4">
                                  <div className="form-group">
                                    <input
                                      className="form-control"
                                      id="ProjectUrl"
                                      type="text"
                                      placeholder="Project Url"
                                      value={createItem.ProjectUrl}
                                      onChange={onEdit}
                                    />
                                  </div>
                                </div>

                                <div className="col-lg-6 mb-4">
                                  <div className="form-group">
                                    <input
                                      className="form-control"
                                      id="VideoUrlOne"
                                      type="text"
                                      placeholder="Video Url One (Optional)"
                                      value={createItem.VideoUrlOne}
                                      onChange={onEdit}
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-6 mb-4">
                                  <div className="form-group">
                                    <input
                                      className="form-control"
                                      id="VideoUrlTwo"
                                      type="text"
                                      placeholder="Video Url Two (Optional)"
                                      value={createItem.VideoUrlTwo}
                                      onChange={onEdit}
                                    />
                                  </div>
                                </div>

                                <div className="col-lg-6 mb-4">
                                  <div className="form-group">
                                    <input
                                      className="form-control"
                                      id="VideoUrlThree"
                                      type="text"
                                      placeholder="Video Url Three (Optional)"
                                      value={createItem.VideoUrlThree}
                                      onChange={onEdit}
                                    />
                                  </div>
                                </div>

                                <div className="col-lg-12 mb-4">
                                  <div className="form-group">
                                    <textarea
                                      className="form-control"
                                      id="ParagraphOne"
                                      type="text"
                                      style={{
                                        height: "120px",
                                      }}
                                      placeholder="Paragraph One"
                                      value={createItem.ParagraphOne}
                                      onChange={onEdit}
                                    ></textarea>
                                  </div>
                                </div>

                                <div className="col-lg-12 mb-4">
                                  <div className="form-group">
                                    <textarea
                                      className="form-control"
                                      id="ParagraphTwo"
                                      type="text"
                                      style={{
                                        height: "120px",
                                      }}
                                      placeholder="Paragraph Two"
                                      value={createItem.ParagraphTwo}
                                      onChange={onEdit}
                                    ></textarea>
                                  </div>
                                </div>

                                <div className="col-lg-6 mb-4">
                                  <div className="form-group">
                                    <label htmlFor="image1">Front Image</label>
                                    <input
                                      type="file"
                                      accept=".jpg, .png"
                                      id="image1"
                                      name="image1"
                                      placeholder="File"
                                      onChange={(e) =>
                                        setSelectedFile1(e.target.files[0])
                                      }
                                    />
                                    <span className="alert-info">
                                      Only .png, .jpg
                                    </span>
                                  </div>
                                </div>

                                <div className="col-lg-6 mb-4">
                                  <div className="form-group">
                                    <label htmlFor="image2">Slider One</label>
                                    <input
                                      type="file"
                                      accept=".jpg, .png"
                                      id="image2"
                                      name="image2"
                                      placeholder="File"
                                      onChange={(e) =>
                                        setSelectedFile2(e.target.files[0])
                                      }
                                    />
                                    <span className="alert-info">
                                      Only .png, .jpg
                                    </span>
                                  </div>
                                </div>

                                <div className="col-lg-6 mb-4">
                                  <div className="form-group">
                                    <label htmlFor="image3">
                                      Slider Two (Optional)
                                    </label>
                                    <input
                                      type="file"
                                      accept=".jpg, .png"
                                      id="image3"
                                      name="image3"
                                      placeholder="File"
                                      onChange={(e) =>
                                        setSlider2(e.target.files[0])
                                      }
                                    />
                                    <span className="alert-info">
                                      Only .png, .jpg
                                    </span>
                                  </div>
                                </div>

                                <div className="col-lg-6 mb-4">
                                  <div className="form-group">
                                    <label htmlFor="image4">
                                      Slider Three (Optional)
                                    </label>
                                    <input
                                      type="file"
                                      accept=".jpg, .png"
                                      id="image4"
                                      name="image4"
                                      placeholder="File"
                                      onChange={(e) =>
                                        setSlider3(e.target.files[0])
                                      }
                                    />
                                    <span className="alert-info">
                                      Only .png, .jpg
                                    </span>
                                  </div>
                                </div>

                                <div className="col-lg-6 mb-4">
                                  <div className="form-group">
                                    <label htmlFor="image5">
                                      Slider Four (Optional)
                                    </label>
                                    <input
                                      type="file"
                                      accept=".jpg, .png"
                                      id="image5"
                                      name="image5"
                                      placeholder="File"
                                      onChange={(e) =>
                                        setSlider4(e.target.files[0])
                                      }
                                    />
                                    <span className="alert-info">
                                      Only .png, .jpg
                                    </span>
                                  </div>
                                </div>

                                <div className="col-lg-6 mb-4">
                                  <div className="form-group">
                                    <label htmlFor="image5">
                                      Slider Five (Optional)
                                    </label>
                                    <input
                                      type="file"
                                      accept=".jpg, .png"
                                      id="image6"
                                      name="image6"
                                      placeholder="File"
                                      onChange={(e) =>
                                        setSlider5(e.target.files[0])
                                      }
                                    />
                                    <span className="alert-info">
                                      Only .png, .jpg
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <div className="modal-footer">
                                <button
                                  type="button"
                                  className="btn btn-default"
                                  data-dismiss="modal"
                                  style={{
                                    backgroundColor: "#da3535",
                                    color: "white",
                                  }}
                                  onClick={getProjects}
                                >
                                  Close
                                </button>
                                <button
                                  type="button"
                                  style={{ backgroundColor: "#008435" }}
                                  className="btn btn-primary"
                                  onClick={onSubmit}
                                >
                                  Save changes
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div class="panel panel-default">
                          <div class="panel-heading">Gallery List</div>
                          <div className="card-body pt-0">
                            <Table data={datatable} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    HomePageData: state.HomePageContents,
    UserProfile: state.LoginDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setOnLoginDetails: (p) => {
      dispatch(setLoginDetails(p));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddGallery);

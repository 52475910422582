import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { serverLink } from "../../resources/Url";
import { showAlert } from "../../pages/sweetalert/sweetalert";
import axios from "axios";
import { setLoginDetails } from "../../actions/Actions";
import { connect } from "react-redux";
import NavBar from "../NavBar";
import Footer from "../Footer";
import Table from "../../pages/table/table";
import Header from "../Header";
import HomeContentLoader from "../HomeContentLoader/HomeContentLoader";
import SubmittingLoader from "../SubmittingLoader/SubmittingLoader";

function AddSlider(props) {
  const [position, setPosition] = useState(0);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedFile1, setSelectedFile1] = useState(null);
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);

  const [states] = useState(
    typeof props?.HomePageData.states !== "undefined"
      ? props?.HomePageData.states
      : []
  );

  const [lga] = useState(
    typeof props?.HomePageData.lga !== "undefined"
      ? props?.HomePageData.lga
      : []
  );

  const [createItem, setCreateItem] = useState({
    SliderId: "",
    Title: "",
    Description: "",
    Image: "",
    IsActive: "",
    Url: "",
  });

  const resetItem = () => {
    setCreateItem({
      ...createItem,
      SliderId: "",
      Title: "",
      Description: "",
      Image: "",
      IsActive: "",
      Url: "",
    });
  };

  const [datatable, setDatatable] = useState({
    columns: [
      {
        label: "S/N",
        field: "sn",
      },
      {
        label: "Title",
        field: "Title",
      },
      {
        label: "Description",
        field: "Description",
      },
      {
        label: "Action",
        field: "action",
      },
    ],
    rows: [],
  });

  const onEdit = async (e) => {
    const id = e.target.id;
    const value = e.target.value;

    if (e.target.type === "file") {
      if (id === "image1") {
        setSelectedFile1(e.target.files[0]);
      }
    } else {
      setCreateItem({
        ...createItem,
        [id]: value,
      });
    }
  };

  const getProjects = async () => {
    await axios
      .get(`${serverLink}shared/get/sliders`)
      .then((result) => {
        if (result.data.length > 0) {
          let rows = [];
          result.data.map((staff, index) => {
            rows.push({
              sn: index + 1,
              SliderId: staff.SliderId,
              Title: staff.Title,
              Description: staff.Description,
              Image: staff.Image,
              IsActive: staff.IsActive,
              Url: staff.Url,
              action: (
                <button
                  type="button"
                  className="btn btn-primary"
                  style={{ backgroundColor: "#008435" }}
                  data-toggle="modal"
                  data-target="#myModal"
                  onClick={() =>
                    setCreateItem({
                      SliderId: staff.SliderId,
                      Title: staff.Title,
                      Description: staff.Description,
                      Image: staff.Image,
                      IsActive: staff.IsActive,
                      Url: staff.Url,
                    })
                  }
                >
                  Edit
                </button>
              ),
            });
          });

          setDatatable({
            ...datatable,
            columns: datatable.columns,
            rows: rows,
          });
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("NETWORK ERROR");
      });
  };

  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState(
    typeof props?.UserProfile !== "undefined" ? props?.UserProfile : []
  );

  useEffect(() => {
    getProjects().then((r) => {});
    const timeoutId = setTimeout(() => {
      setPosition(1);
      setIsLoading(false);
      if (currentUser.length === 0) {
        navigate("/auth");
      }
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [currentUser]);

  const onSubmit = async (e) => {
    setIsSubmittingForm(true);
    e.preventDefault();
    for (let key in createItem) {
      if (
        createItem.hasOwnProperty(key) &&
        key !== "SliderId" &&
        key !== "Url" &&
        key !== "Image"
      ) {
        if (createItem[key] === "") {
          await showAlert("EMPTY FIELD", `Please enter ${key}`, "error");
          setIsSubmittingForm(false);
          return false;
        }
      }
    }

    if (createItem.Image.size / 1024 > 2048) {
      await showAlert("", `Image Size Can't be more than 2MB`, "error");
      setIsSubmittingForm(false);
      return false;
    }

    if (error === "invalid") {
      await showAlert("Input Error", "Invalid Email Address", "error");
      setIsSubmittingForm(false);
      return false;
    }

    if (selectedFile1) {
      const image1 = new FormData();
      image1.append("image1", selectedFile1);

      await axios
        .post(`${serverLink}projects/upload/image1`, image1)
        .then(async (res) => {
          if (res.data.type === "success") {
            const frontImage = res.data.file.url;
            const sendData = {
              SliderId: createItem.SliderId,
              Title: createItem.Title,
              Description: createItem.Description,
              Image: frontImage,
              IsActive: createItem.IsActive,
              Url: createItem.Url,
            };

            console.log("sendData", sendData);
            if (createItem.SliderId === "") {
              axios
                .post(`${serverLink}slider/add`, sendData)
                .then(async (res) => {
                  if (res.data.message === "success") {
                    resetItem();
                    await getProjects();
                    await showAlert(
                      "Success",
                      `Project Added Successfully`,
                      "success"
                    );
                    setIsSubmittingForm(false);
                  }
                  if (res.data.message === "exist") {
                    await showAlert(
                      "Error",
                      `Project with given details already exist!`,
                      "error"
                    );
                    setIsSubmittingForm(false);
                  }
                })
                .catch((error) => {
                  console.log("Error", error);
                });
            }
            if (createItem.SliderId !== "") {
              await axios
                .patch(`${serverLink}slider/update`, sendData)
                .then((result) => {
                  if (result.data.message === "success") {
                    resetItem();
                    getProjects();
                    showAlert(
                      "Success",
                      "Slider Updated Successfully",
                      "success"
                    );
                    setIsSubmittingForm(false);
                  } else {
                    showAlert(
                      "ERROR",
                      "Something went wrong. Please try again!",
                      "error"
                    );
                    setIsSubmittingForm(false);
                  }
                })
                .catch((error) => {
                  showAlert(
                    "NETWORK ERROR",
                    "Please check your connection and try again!",
                    "error"
                  );
                  setIsSubmittingForm(false);
                });
            }
          }
        });
    }
  };

  return (
    <>
      {isLoading ? (
        <HomeContentLoader />
      ) : (
        <div id="wrapper">
          <Header />
          <NavBar />
          <div id="page-wrapper">
            <div class="header">
              <h1 class="page-header">Homepage Sliders</h1>
            </div>
            <div id="page-inner">
              <div class="row">
                <div class="col-md-12">
                  <div class="panel panel-default">
                    <div class="panel-heading"></div>

                    <div class="panel-body">
                      <div className="panel-body">
                        <button
                          style={{
                            backgroundColor: "#008435",
                            float: "right",
                          }}
                          className="btn btn-primary"
                          data-toggle="modal"
                          data-target="#myModal"
                          onClick={resetItem}
                        >
                          Add Slider
                        </button>
                        {isSubmittingForm && <SubmittingLoader />}
                        <div
                          className="modal fade"
                          id="myModal"
                          tabIndex="-1"
                          role="dialog"
                          aria-labelledby="myModalLabel"
                          aria-hidden="true"
                        >
                          <div className="modal-dialog">
                            <div
                              className="modal-content"
                              style={{
                                width: "700px",
                              }}
                            >
                              <div className="modal-header">
                                <button
                                  type="button"
                                  className="close"
                                  data-dismiss="modal"
                                  aria-hidden="true"
                                >
                                  &times;
                                </button>
                                <h4 className="modal-title" id="myModalLabel">
                                  Homepage Slider Component
                                </h4>
                              </div>
                              <div className="modal-body">
                                <div className="col-lg-6 mb-4">
                                  <div className="form-group">
                                    <input
                                      className="form-control"
                                      id="Title"
                                      type="text"
                                      placeholder="Title"
                                      value={createItem.Title}
                                      onChange={onEdit}
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-6 ">
                                  <div className="form-group">
                                    <select
                                      id="IsActive"
                                      className="form-control"
                                      value={createItem.IsActive}
                                      onChange={onEdit}
                                    >
                                      <option value="">Select Status</option>
                                      <option value="1">Active</option>
                                      <option value="0">InActive</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="col-lg-12 mb-4">
                                  <div className="form-group">
                                    <input
                                      className="form-control"
                                      id="Url"
                                      type="text"
                                      placeholder="Slider Url"
                                      value={createItem.Url}
                                      onChange={onEdit}
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-12 mb-4">
                                  <div className="form-group">
                                    <textarea
                                      className="form-control"
                                      id="Description"
                                      type="text"
                                      style={{
                                        height: "120px",
                                      }}
                                      placeholder="Short Description"
                                      value={createItem.Description}
                                      onChange={onEdit}
                                    ></textarea>
                                  </div>
                                </div>

                                <div className="col-lg-12 mb-4">
                                  <div className="form-group">
                                    <label htmlFor="image1">Image</label>
                                    <input
                                      type="file"
                                      accept=".jpg, .png"
                                      id="image1"
                                      name="image1"
                                      placeholder="File"
                                      onChange={(e) =>
                                        setSelectedFile1(e.target.files[0])
                                      }
                                    />
                                    <span className="alert-info">
                                      Only .png, .jpg
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <div className="modal-footer">
                                <button
                                  type="button"
                                  className="btn btn-default"
                                  data-dismiss="modal"
                                  style={{
                                    backgroundColor: "#da3535",
                                    color: "white",
                                  }}
                                  onClick={getProjects}
                                >
                                  Close
                                </button>
                                <button
                                  type="button"
                                  style={{ backgroundColor: "#008435" }}
                                  className="btn btn-primary"
                                  onClick={onSubmit}
                                >
                                  Save changes
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div class="panel panel-default">
                          <div class="panel-heading">Sliders List</div>
                          <div className="card-body pt-0">
                            <Table data={datatable} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    HomePageData: state.HomePageContents,
    UserProfile: state.LoginDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setOnLoginDetails: (p) => {
      dispatch(setLoginDetails(p));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddSlider);

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { serverLink } from "../../resources/Url";
import { showAlert } from "../../pages/sweetalert/sweetalert";
import axios from "axios";
import { setLoginDetails } from "../../actions/Actions";
import { connect } from "react-redux";
import NavBar from "../NavBar";
import Footer from "../Footer";
import Table from "../../pages/table/table";
import Header from "../Header";
import HomeContentLoader from "../HomeContentLoader/HomeContentLoader";
import SubmittingLoader from "../SubmittingLoader/SubmittingLoader";

function AddProject(props) {
  const [position, setPosition] = useState(0);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedFile1, setSelectedFile1] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);
  const [selectedFile3, setSelectedFile3] = useState(null);
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);
  const [lgas, setLGAs] = useState([]);
  const [frontImage, setFrontImage] = useState(null);
  const [mainImage, setMainImage] = useState(null);

  const [states] = useState(
    typeof props?.HomePageData.states !== "undefined"
      ? props?.HomePageData.states
      : []
  );

  const [lga] = useState(
    typeof props?.HomePageData.lga !== "undefined"
      ? props?.HomePageData.lga
      : []
  );

  const [programmes] = useState(
    typeof props?.HomePageData.programmes !== "undefined"
      ? props?.HomePageData.programmes
      : []
  );

  const [createItem, setCreateItem] = useState({
    ProjectId: "",
    Title: "",
    Description: "",
    LongDescription: "",
    FrontImage: "",
    MainImage: "",
    BenefitImage: "",
    Year: "",
    IsActive: "",
    Status: "",
    StateId: "",
    LGAId: "",
    ProgrammeId: "",
    ChannelId: "",
    LiveFeedKey: "",
  });

  const resetItem = () => {
    setCreateItem({
      ...createItem,
      ProjectId: "",
      Title: "",
      Description: "",
      LongDescription: "",
      FrontImage: "",
      MainImage: "",
      BenefitImage: "",
      Year: "",
      IsActive: "",
      Status: "",
      StateId: "",
      LGAId: "",
      ProgrammeId: "",
      ChannelId: "",
      LiveFeedKey: "",
    });
  };

  const [datatable, setDatatable] = useState({
    columns: [
      {
        label: "S/N",
        field: "sn",
      },
      {
        label: "Title",
        field: "Title",
      },
      {
        label: "Description",
        field: "Description",
      },
      {
        label: "Year",
        field: "Year",
      },
      {
        label: "Status",
        field: "Status",
      },
      {
        label: "Action",
        field: "action",
      },
    ],
    rows: [],
  });

  const onEdit = async (e) => {
    const id = e.target.id;
    const value = e.target.value;

    if (e.target.type === "file") {
      if (id === "image1") {
        setSelectedFile1(e.target.files[0]);
      }
      if (id === "image2") {
        setSelectedFile2(e.target.files[0]);
      }
    }

    if (id === "StateId") {
      const selectedStateId = value;
      setCreateItem({
        ...createItem,
        StateId: selectedStateId,
        LGAId: "", // Reset LGAId when the state changes
      });

      try {
        const lgaData = lga.filter(
          (x) => x.StateId === Number(selectedStateId)
        );

        setLGAs(lgaData);
      } catch (error) {
        console.error("Error fetching LGAs:", error);
      }
    } else if (id === "LGAId") {
      setCreateItem({
        ...createItem,
        LGAId: value,
      });
    } else {
      setCreateItem({
        ...createItem,
        [id]: value,
      });
    }
  };

  const getProjects = async () => {
    await axios
      .get(`${serverLink}shared/get/projects`)
      .then((result) => {
        if (result.data.length > 0) {
          let rows = [];
          result.data.map((staff, index) => {
            rows.push({
              sn: index + 1,
              ProjectId: staff.ProjectId,
              Title: staff.Title,
              Description: staff.Description,
              LongDescription: staff.LongDescription,
              FrontImage: staff.FrontImage,
              MainImage: staff.MainImage,
              BenefitImage: staff.BenefitImage,
              Biography: staff.Biography,
              Year: staff.Year,
              IsActive: staff.IsActive,
              Status: staff.Status,
              ProgrammeId: staff.ProgrammeId,
              ChannelId: staff.ChannelId,
              LiveFeedKey: staff.LiveFeedKey,
              action: (
                <button
                  type="button"
                  className="btn btn-primary"
                  style={{ backgroundColor: "#008435" }}
                  data-toggle="modal"
                  data-target="#myModal"
                  onClick={() =>
                    setCreateItem({
                      ProjectId: staff.ProjectId,
                      Title: staff.Title,
                      Description: staff.Description,
                      LongDescription: staff.LongDescription,
                      FrontImage: staff.FrontImage,
                      MainImage: staff.MainImage,
                      BenefitImage: staff.BenefitImage,
                      Biography: staff.Biography,
                      Year: staff.Year,
                      StateId: staff.StateId,
                      LGAId: staff.LGAId,
                      IsActive: staff.IsActive,
                      Status: staff.Status,
                      ProgrammeId: staff.ProgrammeId,
                      ChannelId: staff.ChannelId,
                      LiveFeedKey: staff.LiveFeedKey,
                    })
                  }
                >
                  Edit
                </button>
              ),
            });
          });

          setDatatable({
            ...datatable,
            columns: datatable.columns,
            rows: rows,
          });
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("NETWORK ERROR");
      });
  };

  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState(
    typeof props?.UserProfile !== "undefined" ? props?.UserProfile : []
  );

  useEffect(() => {
    getProjects().then((r) => {});
    const timeoutId = setTimeout(() => {
      setPosition(1);
      setIsLoading(false);
      if (currentUser.length === 0) {
        navigate("/auth");
      }
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [currentUser]);

  const uploadImage = async (formData, endpoint) => {
    try {
      const response = await axios.post(`${serverLink}${endpoint}`, formData);
      if (response.data.type === "success") {
        return response.data.file.url;
      }
      throw new Error("Image upload failed.");
    } catch (error) {
      console.error("Image upload error:", error);
      throw error;
    }
  };

  const onSubmit = async (e) => {
    setIsSubmittingForm(true);
    e.preventDefault();
    for (let key in createItem) {
      if (
        createItem.hasOwnProperty(key) &&
        key !== "ProjectId" &&
        key !== "FrontImage" &&
        key !== "MainImage" &&
        key !== "BenefitImage" &&
        key !== "ChannelId" &&
        key !== "LiveFeedKey"
      ) {
        if (createItem[key] === "") {
          await showAlert("EMPTY FIELD", `Please enter ${key}`, "error");
          setIsSubmittingForm(false);
          return false;
        }
      }
    }

    if (createItem.FrontImage && createItem.FrontImage.size / 1024 > 2048) {
      await showAlert("", `Image Size Can't be more than 2MB`, "error");
      setIsSubmittingForm(false);
      return false;
    }

    if (createItem.MainImage && createItem.MainImage.size / 1024 > 2048) {
      await showAlert("", `Image Size Can't be more than 2MB`, "error");
      setIsSubmittingForm(false);
      return false;
    }

    if (error === "invalid") {
      await showAlert("Input Error", "Invalid Email Address", "error");
      setIsSubmittingForm(false);
      return false;
    }

    if (createItem.Title !== "") {
      const image1 = new FormData();
      image1.append("image1", selectedFile1);

      const image2 = new FormData();
      image2.append("image2", selectedFile2);

      const frontImage =
        selectedFile1 &&
        selectedFile1.size > 0 &&
        (await uploadImage(image1, "projects/upload/image1"));

      const mainImage =
        selectedFile2 &&
        selectedFile2.size > 0 &&
        (await uploadImage(image2, "projects/upload/image2"));

      const sendData = {
        ProjectId: createItem.ProjectId,
        Title: createItem.Title,
        Description: createItem.Description,
        LongDescription: createItem.LongDescription,
        FrontImage: frontImage,
        MainImage: mainImage,
        Year: createItem.Year,
        StateId: createItem.StateId,
        LGAId: createItem.LGAId,
        IsActive: createItem.IsActive,
        Status: createItem.Status,
        ProgrammeId: createItem.ProgrammeId,
        ChannelId: createItem.ChannelId,
        LiveFeedKey: createItem.LiveFeedKey,
      };
      if (createItem.ProjectId === "") {
        axios
          .post(`${serverLink}projects/add`, sendData)
          .then(async (res) => {
            if (res.data.message === "success") {
              resetItem();
              await getProjects();
              await showAlert(
                "Success",
                `Project Added Successfully`,
                "success"
              );
              setIsSubmittingForm(false);
            }
            if (res.data.message === "exist") {
              await showAlert(
                "Error",
                `Project with given details already exist!`,
                "error"
              );
              setIsSubmittingForm(false);
            }
          })
          .catch((error) => {
            console.log("Error", error);
          });
      }
      if (createItem.ProjectId !== "") {
        await axios
          .patch(`${serverLink}projects/update`, sendData)
          .then((result) => {
            if (result.data.message === "success") {
              resetItem();
              getProjects();
              showAlert("Success", "Project Updated Successfully", "success");
              setIsSubmittingForm(false);
            } else {
              showAlert(
                "ERROR",
                "Something went wrong. Please try again!",
                "error"
              );
              setIsSubmittingForm(false);
            }
          })
          .catch((error) => {
            showAlert(
              "NETWORK ERROR",
              "Please check your connection and try again!",
              "error"
            );
            setIsSubmittingForm(false);
          });
      }
    }
  };

  return (
    <>
      {isLoading ? (
        <HomeContentLoader />
      ) : (
        <div id="wrapper">
          <Header />
          <NavBar />
          <div id="page-wrapper">
            <div class="header">
              <h1 class="page-header">Manage Projects</h1>
            </div>
            <div id="page-inner">
              <div class="row">
                <div class="col-md-12">
                  <div class="panel panel-default">
                    <div class="panel-heading"></div>

                    <div class="panel-body">
                      <div className="panel-body">
                        <button
                          style={{
                            backgroundColor: "#008435",
                            float: "right",
                          }}
                          className="btn btn-primary"
                          data-toggle="modal"
                          data-target="#myModal"
                          onClick={resetItem}
                        >
                          Add Project
                        </button>
                        {isSubmittingForm && <SubmittingLoader />}
                        <div
                          className="modal fade"
                          id="myModal"
                          tabIndex="-1"
                          role="dialog"
                          aria-labelledby="myModalLabel"
                          aria-hidden="true"
                        >
                          <div className="modal-dialog">
                            <div
                              className="modal-content"
                              style={{
                                width: "700px",
                              }}
                            >
                              <div className="modal-header">
                                <button
                                  type="button"
                                  className="close"
                                  data-dismiss="modal"
                                  aria-hidden="true"
                                >
                                  &times;
                                </button>
                                <h4 className="modal-title" id="myModalLabel">
                                  Projects Component
                                </h4>
                              </div>
                              <div className="modal-body">
                                <div className="col-lg-6 mb-4">
                                  <div className="form-group">
                                    <input
                                      className="form-control"
                                      id="Title"
                                      type="text"
                                      placeholder="Title"
                                      value={createItem.Title}
                                      onChange={onEdit}
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-6 mb-4">
                                  <div className="form-group">
                                    <input
                                      className="form-control"
                                      id="Year"
                                      type="number"
                                      placeholder="Year"
                                      value={createItem.Year}
                                      onChange={onEdit}
                                    />
                                  </div>
                                </div>

                                <div className="col-lg-6 mb-4">
                                  <div className="form-group">
                                    <input
                                      className="form-control"
                                      id="ChannelId"
                                      type="text"
                                      placeholder="Channel Id"
                                      value={createItem.ChannelId}
                                      onChange={onEdit}
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-6 mb-4">
                                  <div className="form-group">
                                    <input
                                      className="form-control"
                                      id="LiveFeedKey"
                                      type="text"
                                      placeholder="Live Feed Key"
                                      value={createItem.LiveFeedKey}
                                      onChange={onEdit}
                                    />
                                  </div>
                                </div>

                                <div className="col-lg-6 mb-4">
                                  <div className="form-group">
                                    <select
                                      className="form-control"
                                      id="StateId"
                                      name="StateId"
                                      onChange={onEdit}
                                      value={createItem.StateId}
                                    >
                                      <option value="">Select State</option>
                                      {states.length > 0 &&
                                        states.map((item, index) => {
                                          return (
                                            <option
                                              key={item.StateId}
                                              value={item.StateId}
                                            >
                                              {item.Title}
                                            </option>
                                          );
                                        })}
                                    </select>
                                  </div>
                                </div>

                                <div className="col-lg-6 mb-4">
                                  <div className="form-group">
                                    <select
                                      className="form-control"
                                      id="LGAId"
                                      name="LGAId"
                                      disabled={createItem.StateId === ""}
                                      onChange={onEdit}
                                      value={createItem.LGAId}
                                    >
                                      <option value="">Select LGA</option>
                                      {lgas.length > 0 &&
                                        lgas.map((item, index) => {
                                          return (
                                            <option
                                              key={item.LGAId}
                                              value={item.LGAId}
                                            >
                                              {item.Title}
                                            </option>
                                          );
                                        })}
                                    </select>
                                  </div>
                                </div>

                                <div className="col-lg-12 mb-4">
                                  <div className="form-group">
                                    <select
                                      className="form-control"
                                      id="ProgrammeId"
                                      name="ProgrammeId"
                                      onChange={onEdit}
                                      value={createItem.ProgrammeId}
                                    >
                                      <option value="">Select Programme</option>
                                      {programmes.length > 0 &&
                                        programmes.map((item, index) => {
                                          return (
                                            <option
                                              key={item.ProgrammeId}
                                              value={item.ProgrammeId}
                                            >
                                              {item.Title}
                                            </option>
                                          );
                                        })}
                                    </select>
                                  </div>
                                </div>

                                <div className="col-lg-12 mb-4">
                                  <div className="form-group">
                                    <textarea
                                      className="form-control"
                                      id="Description"
                                      type="text"
                                      style={{
                                        height: "120px",
                                      }}
                                      placeholder="Short Description"
                                      value={createItem.Description}
                                      onChange={onEdit}
                                    ></textarea>
                                  </div>
                                </div>

                                <div className="col-lg-12 mb-4">
                                  <div className="form-group">
                                    <textarea
                                      className="form-control"
                                      id="LongDescription"
                                      type="text"
                                      style={{
                                        height: "120px",
                                      }}
                                      placeholder="Long Description"
                                      value={createItem.LongDescription}
                                      onChange={onEdit}
                                    ></textarea>
                                  </div>
                                </div>

                                <div className="col-lg-6 ">
                                  <div className="form-group">
                                    <select
                                      id="Status"
                                      className="form-control"
                                      value={createItem.Status}
                                      onChange={onEdit}
                                    >
                                      <option value="">Select Progress</option>
                                      <option value="Completed">
                                        Completed
                                      </option>
                                      <option value="Ongoing">Ongoing</option>
                                    </select>
                                  </div>
                                </div>

                                <div className="col-lg-6 ">
                                  <div className="form-group">
                                    <select
                                      id="IsActive"
                                      className="form-control"
                                      value={createItem.IsActive}
                                      onChange={onEdit}
                                    >
                                      <option value="">Select Status</option>
                                      <option value="1">Active</option>
                                      <option value="0">InActive</option>
                                    </select>
                                  </div>
                                </div>

                                <div className="col-lg-6 mb-4">
                                  <div className="form-group">
                                    <label htmlFor="image1">Front Image</label>
                                    <input
                                      type="file"
                                      accept=".jpg, .png"
                                      id="image1"
                                      name="image1"
                                      placeholder="File"
                                      onChange={(e) =>
                                        setSelectedFile1(e.target.files[0])
                                      }
                                    />
                                    <span className="alert-info">
                                      Only .png, .jpg
                                    </span>
                                  </div>
                                </div>

                                <div className="col-lg-6 mb-4">
                                  <div className="form-group">
                                    <label htmlFor="image2">Main Image</label>
                                    <input
                                      type="file"
                                      accept=".jpg, .png"
                                      id="image2"
                                      name="image2"
                                      placeholder="File"
                                      onChange={(e) =>
                                        setSelectedFile2(e.target.files[0])
                                      }
                                    />
                                    <span className="alert-info">
                                      Only .png, .jpg
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <div className="modal-footer">
                                <button
                                  type="button"
                                  className="btn btn-default"
                                  data-dismiss="modal"
                                  style={{
                                    backgroundColor: "#da3535",
                                    color: "white",
                                  }}
                                  onClick={getProjects}
                                >
                                  Close
                                </button>
                                <button
                                  type="button"
                                  style={{ backgroundColor: "#008435" }}
                                  className="btn btn-primary"
                                  onClick={onSubmit}
                                >
                                  Save changes
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div class="panel panel-default">
                          <div class="panel-heading">Projects List</div>
                          <div className="card-body pt-0">
                            <Table data={datatable} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    HomePageData: state.HomePageContents,
    UserProfile: state.LoginDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setOnLoginDetails: (p) => {
      dispatch(setLoginDetails(p));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddProject);

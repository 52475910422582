import React, { useState } from "react";

const DynamicForm = () => {
  const [formData, setFormData] = useState([""]); // Initial input field

  const handleInputChange = (index, value) => {
    const updatedFormData = [...formData];
    updatedFormData[index] = value;
    setFormData(updatedFormData);
  };

  const handleAddField = () => {
    setFormData([...formData, ""]);
  };

  const handleRemoveField = (index) => {
    const updatedFormData = [...formData];
    updatedFormData.splice(index, 1);
    setFormData(updatedFormData);
  };

  const handleSubmit = async () => {
    // Send formData to your server and store it in the MySQL database.
    try {
      const response = await fetch("/api/addFormData", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ data: formData }),
      });

      if (response.ok) {
        // Data successfully added to the database
        console.log("Data added to MySQL database");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div>
      {formData.map((field, index) => (
        <div key={index}>
          <input
            type="text"
            value={field}
            onChange={(e) => handleInputChange(index, e.target.value)}
          />
          <button onClick={() => handleRemoveField(index)}>Remove</button>
        </div>
      ))}
      <button onClick={handleAddField}>Add Field</button>
      <button onClick={handleSubmit}>Submit</button>
    </div>
  );
};

export default DynamicForm;

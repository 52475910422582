import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import arrayShuffle from "array-shuffle";
import { connect } from "react-redux";
import { currencyConverter, formatDate, serverLink } from "../resources/Url";
import { setLoginDetails } from "../actions/Actions";
import NavBar from "./NavBar";
import Header from "./Header";
import axios from "axios";
import logo from "../resources/mibusiness-report.png";
import Footer from "./Footer";

const Home = (props) => {
  const navigate = useNavigate();
  const [date, setDate] = useState(new Date());
  const [position, setPosition] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState(
    typeof props?.UserProfile !== "undefined" ? props?.UserProfile : []
  );

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const currentDate = new Date();

  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1; // Note: months are zero-indexed
  const currentDay = currentDate.getDate();

  const [monthlyGeneralSales, setMonthlyGeneralSales] = useState([]);
  const [dailyGeneralSales, setDailyGeneralSales] = useState([]);

  const [monthlyCafeSales, setMonthlyCafeSales] = useState([]);
  const [monthlyLaundrySales, setMonthlyLaundrySales] = useState([]);

  const [dailyLaundrySales, setDailyLaundrySales] = useState([]);
  const [dailyCafeSales, setDailyCafeSales] = useState([]);

  const [currentYearSales, setCurrentYearSales] = useState([]);
  const [allYearsSales, setAllYearsSales] = useState([]);

  const [projects] = useState(
    typeof props?.HomePageData.projects !== "undefined"
      ? props?.HomePageData.projects
      : []
  );

  const [programmes] = useState(
    typeof props?.HomePageData.programmes !== "undefined"
      ? arrayShuffle(props?.HomePageData.programmes)
      : []
  );

  const [asset] = useState(
    typeof props?.HomePageData.assets !== "undefined"
      ? arrayShuffle(props?.HomePageData.assets)
      : []
  );

  const [states] = useState(
    typeof props?.HomePageData.states !== "undefined"
      ? arrayShuffle(props?.HomePageData.states)
      : []
  );

  const totalProgrammes = programmes?.length || 0;
  const totalProjects = projects?.length || 0;
  const totalAssets = asset?.length || 0;
  const totalStates = states?.length || 0;

  useEffect(() => {
    const timerID = setInterval(() => tick(), 1000);

    return function cleanup() {
      clearInterval(timerID);
    };
  });

  function tick() {
    setDate(new Date());
  }

  const getSalesSummary = async () => {
    await axios
      .get(`${serverLink}shared/get/sales`)
      .then((result) => {
        if (result.data.length > 0) {
          const selectedData = formatDate(currentDate);
          const year = selectedData.split("-")[0];
          const month = selectedData.split("-")[1];
          const day = selectedData.split("-")[2];

          const searchableDateForm = `${month
            .toString()
            .padStart(2, "0")
            .replace(/^0/, "")}/${day
            .toString()
            .padStart(2, "0")
            .replace(/^0/, "")}/${year}`;

          const timeoutId = setTimeout(() => {
            setPosition(1);

            // DAILY START
            const dailyGeneralReportArray = result.data.filter(
              (item) => item.DateOfEntry === searchableDateForm.trim()
            );

            const dailyCafeReportArray = result.data.filter(
              (item) => item.BusinessID === Number(1)
            );

            const dailyLaundryReportArray = result.data.filter(
              (item) => item.BusinessID === Number(2)
            );

            // DAILY ENDS

            // DAILY START
            const monthlyGeneralReportArray = result.data.filter(
              (item) => item.Month === Number(month)
            );

            const monthlyCafeReportArray = result.data.filter(
              (item) =>
                item.BusinessID === Number(1) && item.Month === Number(month)
            );

            const monthlyLaundryReportArray = result.data.filter(
              (item) =>
                item.BusinessID === Number(2) && item.Month === Number(month)
            );

            const yearlyReportArray = result.data.filter(
              (item) => item.Year === Number(year)
            );

            // DAILY ENDS

            if (dailyGeneralReportArray) {
              setDailyGeneralSales(
                dailyGeneralReportArray.reduce((a, r) => (a = a + r.Total), 0)
              );
            }

            if (dailyCafeReportArray) {
              setDailyCafeSales(
                dailyCafeReportArray.reduce((a, r) => (a = a + r.Total), 0)
              );
            }

            if (dailyLaundryReportArray) {
              setDailyLaundrySales(
                dailyLaundryReportArray.reduce((a, r) => (a = a + r.Total), 0)
              );
            }

            if (monthlyGeneralReportArray) {
              setMonthlyGeneralSales(
                monthlyGeneralReportArray.reduce((a, r) => (a = a + r.Total), 0)
              );
            }

            if (monthlyCafeReportArray) {
              setMonthlyCafeSales(
                monthlyCafeReportArray.reduce((a, r) => (a = a + r.Total), 0)
              );
            }

            if (monthlyLaundryReportArray) {
              setMonthlyLaundrySales(
                monthlyLaundryReportArray.reduce((a, r) => (a = a + r.Total), 0)
              );
            }

            if (yearlyReportArray) {
              setCurrentYearSales(
                yearlyReportArray.reduce((a, r) => (a = a + r.Total), 0)
              );
            }

            if (result.data) {
              setAllYearsSales(
                result.data.reduce((a, r) => (a = a + r.Total), 0)
              );
            }

            setPosition(1);
            setIsLoading(false);
          }, 5000);

          return () => clearTimeout(timeoutId);
        }
      })
      .catch((err) => {
        console.log("NETWORK ERROR", err);
      });
  };

  useEffect(() => {
    getSalesSummary().then((r) => {});
    if (currentUser.length === 0) {
      navigate("/auth");
    }
  }, [currentUser]);

  return (
    <>
      <div id="wrapper">
        <Header />
        <NavBar />
        <div id="page-wrapper">
          <div className="header">
            <h1 className="page-header">Dashboard</h1>
          </div>

          <div id="page-inner">
            <div className="row">
              <div className="col-md-12">
                <div className="panel panel-default">
                  <div className="panel-heading"></div>

                  <div className="panel-body">
                    <div className="col-md-12">
                      <div className="modal-body">
                        <div>
                          <h3 className="col-xs-6 col-md-3">
                            Current Time: {date.toLocaleTimeString()}
                          </h3>
                          <h3 className="col-xs-6 col-md-3">
                            Current Date: {date.toLocaleDateString()}
                          </h3>

                          <h3 className="col-xs-6 col-md-3">
                            Signed In As: {currentUser.FullName}
                          </h3>
                          <div className="panel-heading"></div>
                          <div className="panel-heading"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {currentUser.Permission === 1 && (
              <>
                <div className="row">
                  <div className="col-md-3 col-sm-12 col-xs-12">
                    <div className="panel panel-primary text-center no-boder blue">
                      <div className="panel-left pull-left blue">
                        <i className="fa fa-money fa-5x"></i>
                      </div>
                      <div className="panel-right">
                        <h3
                          style={{
                            fontSize: "24px",
                          }}
                        >
                          {totalProjects}
                        </h3>
                        <strong> Projects</strong>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-12 col-xs-12">
                    <div className="panel panel-primary text-center no-boder blue">
                      <div className="panel-left pull-left blue">
                        <i className="fa fa-shopping-cart fa-5x"></i>
                      </div>

                      <div className="panel-right">
                        <h3
                          style={{
                            fontSize: "24px",
                          }}
                        >
                          {totalProgrammes}
                        </h3>
                        <strong> Programmes</strong>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3 col-sm-12 col-xs-12">
                    <div className="panel panel-primary text-center no-boder blue">
                      <div className="panel-left pull-left blue">
                        <i className="fa fa-shopping-cart fa-5x"></i>
                      </div>

                      <div className="panel-right">
                        <h3
                          style={{
                            fontSize: "24px",
                          }}
                        >
                          {totalAssets}
                        </h3>
                        <strong> Assets</strong>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-12 col-xs-12">
                    <div className="panel panel-primary text-center no-boder blue">
                      <div className="panel-left pull-left blue">
                        <i className="fa fa fa-comments fa-5x"></i>
                      </div>
                      <div className="panel-right">
                        <h3
                          style={{
                            fontSize: "24px",
                          }}
                        >
                          {totalStates}
                        </h3>
                        <strong>State Coverage</strong>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12"></div>
                </div>
              </>
            )}

            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    HomePageData: state.HomePageContents,
    UserProfile: state.LoginDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setOnLoginDetails: (p) => {
      dispatch(setLoginDetails(p));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);

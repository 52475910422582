import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { serverLink } from "../../resources/Url";
import { showAlert } from "../../pages/sweetalert/sweetalert";
import axios from "axios";
import { setLoginDetails } from "../../actions/Actions";
import { connect } from "react-redux";
import NavBar from "../NavBar";
import Footer from "../Footer";
import Table from "../../pages/table/table";
import Header from "../Header";
import HomeContentLoader from "../HomeContentLoader/HomeContentLoader";
import SubmittingLoader from "../SubmittingLoader/SubmittingLoader";

function AddAddress(props) {
  const [position, setPosition] = useState(0);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedFile1, setSelectedFile1] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);
  const [selectedFile3, setSelectedFile3] = useState(null);
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);
  const [lgas, setLGAs] = useState([]);
  const [frontImage, setFrontImage] = useState(null);
  const [mainImage, setMainImage] = useState(null);

  const [states] = useState(
    typeof props?.HomePageData.states !== "undefined"
      ? props?.HomePageData.states
      : []
  );

  const [lga] = useState(
    typeof props?.HomePageData.lga !== "undefined"
      ? props?.HomePageData.lga
      : []
  );

  const [programmes] = useState(
    typeof props?.HomePageData.programmes !== "undefined"
      ? props?.HomePageData.programmes
      : []
  );

  const [createItem, setCreateItem] = useState({
    AddressId: "",
    StateId: "",
    LGAId: "",
    Address: "",
    GoogleMap: "",
    IsActive: "",
  });

  const resetItem = () => {
    setCreateItem({
      ...createItem,
      AddressId: "",
      StateId: "",
      LGAId: "",
      Address: "",
      GoogleMap: "",
      IsActive: "",
    });
  };

  const [datatable, setDatatable] = useState({
    columns: [
      {
        label: "S/N",
        field: "sn",
      },
      {
        label: "Address",
        field: "Address",
      },
      {
        label: "State",
        field: "StateId",
      },
      {
        label: "LGA",
        field: "LGAId",
      },
      {
        label: "Action",
        field: "action",
      },
    ],
    rows: [],
  });

  const onEdit = async (e) => {
    const id = e.target.id;
    const value = e.target.value;

    if (id === "StateId") {
      const selectedStateId = value;
      setCreateItem({
        ...createItem,
        StateId: selectedStateId,
        LGAId: "", // Reset LGAId when the state changes
      });

      try {
        const lgaData = lga.filter(
          (x) => x.StateId === Number(selectedStateId)
        );

        setLGAs(lgaData);
      } catch (error) {
        console.error("Error fetching LGAs:", error);
      }
    } else if (id === "LGAId") {
      setCreateItem({
        ...createItem,
        LGAId: value,
      });
    } else {
      setCreateItem({
        ...createItem,
        [id]: value,
      });
    }
  };

  const getProjects = async () => {
    await axios
      .get(`${serverLink}shared/get/address`)
      .then((result) => {
        if (result.data.length > 0) {
          let rows = [];
          result.data.map((staff, index) => {
            const state = states.filter((x) => x.StateId === staff.StateId)[0];
            const lgas = lga.filter((x) => x.LGAId === staff.LGAId)[0];
            rows.push({
              sn: index + 1,
              AddressId: staff.AddressId,
              Address: staff.Address,
              StateId: state.Title,
              LGAId: lgas.Title,
              GoogleMap: staff.GoogleMap,
              IsActive: staff.IsActive,
              action: (
                <button
                  type="button"
                  className="btn btn-primary"
                  style={{ backgroundColor: "#008435" }}
                  data-toggle="modal"
                  data-target="#myModal"
                  onClick={() =>
                    setCreateItem({
                      AddressId: staff.AddressId,
                      Address: staff.Address,
                      StateId: staff.StateId,
                      LGAId: staff.LGAId,
                      GoogleMap: staff.GoogleMap,
                      IsActive: staff.IsActive,
                    })
                  }
                >
                  Edit
                </button>
              ),
            });
          });

          setDatatable({
            ...datatable,
            columns: datatable.columns,
            rows: rows,
          });
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("NETWORK ERROR");
      });
  };

  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState(
    typeof props?.UserProfile !== "undefined" ? props?.UserProfile : []
  );

  useEffect(() => {
    getProjects().then((r) => {});
    const timeoutId = setTimeout(() => {
      setPosition(1);
      setIsLoading(false);
      if (currentUser.length === 0) {
        navigate("/auth");
      }
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [currentUser]);

  const onSubmit = async (e) => {
    setIsSubmittingForm(true);
    e.preventDefault();
    for (let key in createItem) {
      if (createItem.hasOwnProperty(key) && key !== "AddressId") {
        if (createItem[key] === "") {
          await showAlert("EMPTY FIELD", `Please enter ${key}`, "error");
          setIsSubmittingForm(false);
          return false;
        }
      }
    }

    if (createItem.Address !== "") {
      const sendData = {
        AddressId: createItem.AddressId,
        Address: createItem.Address,
        StateId: createItem.StateId,
        LGAId: createItem.LGAId,
        GoogleMap: createItem.GoogleMap,
        IsActive: createItem.IsActive,
      };
      if (createItem.AddressId === "") {
        axios
          .post(`${serverLink}address/add`, sendData)
          .then(async (res) => {
            if (res.data.message === "success") {
              resetItem();
              await getProjects();
              await showAlert(
                "Success",
                `Address Added Successfully`,
                "success"
              );
              setIsSubmittingForm(false);
            }
            if (res.data.message === "exist") {
              await showAlert(
                "Error",
                `Address with given details already exist!`,
                "error"
              );
              setIsSubmittingForm(false);
            }
          })
          .catch((error) => {
            console.log("Error", error);
          });
      }
      if (createItem.AddressId !== "") {
        await axios
          .patch(`${serverLink}address/update`, sendData)
          .then((result) => {
            if (result.data.message === "success") {
              resetItem();
              getProjects();
              showAlert("Success", "Address Updated Successfully", "success");
              setIsSubmittingForm(false);
            } else {
              showAlert(
                "ERROR",
                "Something went wrong. Please try again!",
                "error"
              );
              setIsSubmittingForm(false);
            }
          })
          .catch((error) => {
            showAlert(
              "NETWORK ERROR",
              "Please check your connection and try again!",
              "error"
            );
            setIsSubmittingForm(false);
          });
      }
    }
  };

  return (
    <>
      {isLoading ? (
        <HomeContentLoader />
      ) : (
        <div id="wrapper">
          <Header />
          <NavBar />
          <div id="page-wrapper">
            <div class="header">
              <h1 class="page-header">Manage Addresses</h1>
            </div>
            <div id="page-inner">
              <div class="row">
                <div class="col-md-12">
                  <div class="panel panel-default">
                    <div class="panel-heading"></div>

                    <div class="panel-body">
                      <div className="panel-body">
                        <button
                          style={{
                            backgroundColor: "#008435",
                            float: "right",
                          }}
                          className="btn btn-primary"
                          data-toggle="modal"
                          data-target="#myModal"
                          onClick={resetItem}
                        >
                          Add Address
                        </button>
                        {isSubmittingForm && <SubmittingLoader />}
                        <div
                          className="modal fade"
                          id="myModal"
                          tabIndex="-1"
                          role="dialog"
                          aria-labelledby="myModalLabel"
                          aria-hidden="true"
                        >
                          <div className="modal-dialog">
                            <div
                              className="modal-content"
                              style={{
                                width: "700px",
                              }}
                            >
                              <div className="modal-header">
                                <button
                                  type="button"
                                  className="close"
                                  data-dismiss="modal"
                                  aria-hidden="true"
                                >
                                  &times;
                                </button>
                                <h4 className="modal-title" id="myModalLabel">
                                  Address Component
                                </h4>
                              </div>
                              <div className="modal-body">
                                <div className="col-lg-6 mb-4">
                                  <div className="form-group">
                                    <select
                                      className="form-control"
                                      id="StateId"
                                      name="StateId"
                                      onChange={onEdit}
                                      value={createItem.StateId}
                                    >
                                      <option value="">Select State</option>
                                      {states.length > 0 &&
                                        states.map((item, index) => {
                                          return (
                                            <option
                                              key={item.StateId}
                                              value={item.StateId}
                                            >
                                              {item.Title}
                                            </option>
                                          );
                                        })}
                                    </select>
                                  </div>
                                </div>
                                <div className="col-lg-6 mb-4">
                                  <div className="form-group">
                                    <select
                                      className="form-control"
                                      id="LGAId"
                                      name="LGAId"
                                      disabled={createItem.StateId === ""}
                                      onChange={onEdit}
                                      value={createItem.LGAId}
                                    >
                                      <option value="">Select LGA</option>
                                      {lgas.length > 0 &&
                                        lgas.map((item, index) => {
                                          return (
                                            <option
                                              key={item.LGAId}
                                              value={item.LGAId}
                                            >
                                              {item.Title}
                                            </option>
                                          );
                                        })}
                                    </select>
                                  </div>
                                </div>
                                <div className="col-lg-6 mb-4">
                                  <div className="form-group">
                                    <input
                                      className="form-control"
                                      id="GoogleMap"
                                      type="text"
                                      placeholder="Google Map"
                                      value={createItem.GoogleMap}
                                      onChange={onEdit}
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-6 ">
                                  <div className="form-group">
                                    <select
                                      id="IsActive"
                                      className="form-control"
                                      value={createItem.IsActive}
                                      onChange={onEdit}
                                    >
                                      <option value="">Select Status</option>
                                      <option value="1">Active</option>
                                      <option value="0">InActive</option>
                                    </select>
                                  </div>
                                </div>

                                <div className="col-lg-12 mb-4">
                                  <div className="form-group">
                                    <textarea
                                      className="form-control"
                                      id="Address"
                                      type="text"
                                      style={{
                                        height: "120px",
                                      }}
                                      placeholder="Address Description"
                                      value={createItem.Address}
                                      onChange={onEdit}
                                    ></textarea>
                                  </div>
                                </div>
                              </div>

                              <div className="modal-footer">
                                <button
                                  type="button"
                                  className="btn btn-default"
                                  data-dismiss="modal"
                                  style={{
                                    backgroundColor: "#da3535",
                                    color: "white",
                                  }}
                                  onClick={getProjects}
                                >
                                  Close
                                </button>
                                <button
                                  type="button"
                                  style={{ backgroundColor: "#008435" }}
                                  className="btn btn-primary"
                                  onClick={onSubmit}
                                >
                                  Save changes
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div class="panel panel-default">
                          <div class="panel-heading">Address List</div>
                          <div className="card-body pt-0">
                            <Table data={datatable} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    HomePageData: state.HomePageContents,
    UserProfile: state.LoginDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setOnLoginDetails: (p) => {
      dispatch(setLoginDetails(p));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddAddress);

import React from "react";
import { setLoginDetails } from "../actions/Actions";
import { connect } from "react-redux";

function Footer(props) {
  // useEffect(() => {
  //   if (!currentUser) {
  //     navigate("/auth");
  //   }
  // }, [currentUser]);

  return (
    <>
      <footer>
        <p>
          All right reserved{" "}
          <a href="https://www.nalda.gov.ng" target="_blank">
            NALDA
          </a>
        </p>
      </footer>
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    HomePageData: state.HomePageContents,
    UserProfile: state.LoginDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setOnLoginDetails: (p) => {
      dispatch(setLoginDetails(p));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);

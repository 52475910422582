import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { setLoginDetails } from "../actions/Actions";
import { connect } from "react-redux";

function NavBar(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const pathSegments = location.pathname
    .split("/")
    .filter((segment) => segment !== "");
  const firstItem = pathSegments[0];

  const [isHRMenuClick, setIsHRMenuClick] = useState(false);
  const [isAdminMenuClick, setIsAdminMenuClick] = useState(false);
  const [isGeneralMenuClick, setIsGeneralMenuClick] = useState(false);
  const [isDashboardMenuClick, setIsDashboardMenuClick] = useState(false);

  // Function to store the selected menu item in localStorage
  const setSelectedMenuItem = (item) => {
    localStorage.setItem("selectedMenuItem", item);
  };

  // Function to get the selected menu item from localStorage
  const getSelectedMenuItem = () => {
    return localStorage.getItem("selectedMenuItem");
  };

  // Set the initial selected menu item based on localStorage
  useEffect(() => {
    const selectedMenuItem = getSelectedMenuItem();
    if (selectedMenuItem) {
      switch (selectedMenuItem) {
        case "HR":
          openHRMenu();
          break;
        case "Admin":
          openAdminMenu();
          break;
        case "General":
          openGeneralMenu();
          break;
        case "Dashboard":
          openDashboardMenu();
          break;
        // Add cases for other menu items as needed
        default:
          break;
      }
    }
  }, []);

  const openHRMenu = () => {
    setIsAdminMenuClick(false);
    setIsGeneralMenuClick(false);
    setIsHRMenuClick(true);
    setSelectedMenuItem("HR");
  };

  const openAdminMenu = () => {
    setIsGeneralMenuClick(false);
    setIsHRMenuClick(false);
    setIsAdminMenuClick(true);
    setSelectedMenuItem("Admin");
  };

  const openGeneralMenu = () => {
    setIsHRMenuClick(false);
    setIsAdminMenuClick(false);
    setIsGeneralMenuClick(true);
    setSelectedMenuItem("General");
  };

  const openDashboardMenu = () => {
    setIsHRMenuClick(false);
    setIsAdminMenuClick(false);
    setIsGeneralMenuClick(false);
    setSelectedMenuItem("Dashboard");
  };

  const [currentUser] = useState(
    typeof props?.UserProfile !== "undefined" ? props?.UserProfile : []
  );

  useEffect(() => {
    if (!currentUser) {
      navigate("/auth");
    }
  }, [currentUser]);

  return (
    <>
      <div id="wrapper">
        <nav className="navbar-default navbar-side" role="navigation">
          <div id="sideNav" href="#">
            <i className="fa fa-caret-right"></i>
          </div>
          <div className="sidebar-collapse">
            {currentUser && currentUser.Permission === 1 && (
              <ul className="nav" id="main-menu">
                <li onClick={openDashboardMenu}>
                  <Link
                    to="/home"
                    className={
                      firstItem && firstItem.includes("home")
                        ? `active-menu`
                        : ""
                    }
                  >
                    <i className="fa fa-dashboard"></i> Dashboard
                  </Link>
                </li>
                <li onClick={openHRMenu}>
                  <a href="#">
                    <i className="fa fa-users"></i> Human Resource
                    <span className="fa arrow"></span>
                  </a>
                  <ul
                    className={`nav nav-second-level collapse ${
                      isHRMenuClick && "in"
                    }`}
                    arial-extended="true"
                  >
                    <li onClick={openHRMenu}>
                      <Link
                        to="/staff"
                        className={
                          firstItem && firstItem.includes("staff")
                            ? `active-menu`
                            : ""
                        }
                      >
                        <i className="fa fa-users"></i> Manage Staff
                      </Link>
                    </li>
                    <li onClick={openHRMenu}>
                      <Link
                        to="/report"
                        className={
                          firstItem && firstItem.includes("report")
                            ? `active-menu`
                            : ""
                        }
                      >
                        <i className="fa fa-book"></i> Reports
                      </Link>
                    </li>
                  </ul>
                </li>
                <li onClick={openAdminMenu}>
                  <a href="#">
                    <i className="fa fa-book"></i> Administration & Record
                    <span className="fa arrow"></span>
                  </a>
                  <ul
                    className={`nav nav-second-level collapse ${
                      isAdminMenuClick && "in"
                    }`}
                    arial-extended="true"
                  >
                    <li>
                      <Link
                        to="/projects"
                        className={
                          firstItem && firstItem.includes("projects")
                            ? `active-menu`
                            : ""
                        }
                      >
                        <i className="fa fa-bar-chart-o"></i> Manage Projects
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/programme"
                        className={
                          firstItem && firstItem.includes("programme")
                            ? `active-menu`
                            : ""
                        }
                      >
                        <i className="fa fa-bar-chart-o"></i> Manage Programmes
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/assets"
                        className={
                          firstItem && firstItem.includes("assets")
                            ? `active-menu`
                            : ""
                        }
                      >
                        <i className="fa fa-bar-chart-o"></i> Manage Assets
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/asset/type"
                        className={
                          firstItem && firstItem.includes("asset/type")
                            ? `active-menu`
                            : ""
                        }
                      >
                        <i className="fa fa-bar-chart-o"></i> Manage Asset Types
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/message"
                        className={
                          firstItem && firstItem.includes("message")
                            ? `active-menu`
                            : ""
                        }
                      >
                        <i className="fa fa-bar-chart-o"></i> Manage Messages
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/jobs"
                        className={
                          firstItem && firstItem.includes("jobs")
                            ? `active-menu`
                            : ""
                        }
                      >
                        <i className="fa fa-book"></i> Jobs
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/report"
                        className={
                          firstItem && firstItem.includes("report")
                            ? `active-menu`
                            : ""
                        }
                      >
                        <i className="fa fa-book"></i> Reports
                      </Link>
                    </li>
                  </ul>
                </li>
                <li onClick={openGeneralMenu}>
                  <a href="#">
                    <i className="fa fa-users"></i> General & Records
                    <span className="fa arrow"></span>
                  </a>
                  <ul
                    className={`nav nav-second-level collapse ${
                      isGeneralMenuClick && "in"
                    }`}
                    arial-extended="true"
                  >
                    <li>
                      <Link
                        to="/news"
                        className={
                          firstItem && firstItem.includes("news")
                            ? `active-menu`
                            : ""
                        }
                      >
                        <i className="fa fa-bar-chart-o"></i> Manage News
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/gallery"
                        className={
                          firstItem && firstItem.includes("gallery")
                            ? `active-menu`
                            : ""
                        }
                      >
                        <i className="fa fa-bar-chart-o"></i> Manage Gallery
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/slider"
                        className={
                          firstItem && firstItem.includes("slider")
                            ? `active-menu`
                            : ""
                        }
                      >
                        <i className="fa fa-bar-chart-o"></i> Manage Sliders
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/states"
                        className={
                          firstItem && firstItem.includes("states")
                            ? `active-menu`
                            : ""
                        }
                      >
                        <i className="fa fa-bar-chart-o"></i> Manage States
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/lga"
                        className={
                          firstItem && firstItem.includes("lga")
                            ? `active-menu`
                            : ""
                        }
                      >
                        <i className="fa fa-bar-chart-o"></i> Manage LGAs
                      </Link>
                    </li>

                    <li>
                      <Link
                        to="/address"
                        className={
                          firstItem && firstItem.includes("address")
                            ? `active-menu`
                            : ""
                        }
                      >
                        <i className="fa fa-bar-chart-o"></i> Manage Address
                      </Link>
                    </li>

                    <li>
                      <Link
                        to="/resources"
                        className={
                          firstItem && firstItem.includes("resources")
                            ? `active-menu`
                            : ""
                        }
                      >
                        <i className="fa fa-bar-chart-o"></i> Manage Resources
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/report"
                        className={
                          firstItem && firstItem.includes("report")
                            ? `active-menu`
                            : ""
                        }
                      >
                        <i className="fa fa-book"></i> Reports
                      </Link>
                    </li>
                  </ul>
                </li>
              </ul>
            )}
            <ul className="nav" id="main-menu">
              <li>
                <Link
                  onClick={""}
                  className={
                    firstItem && firstItem.includes("logout")
                      ? `active-menu`
                      : ""
                  }
                >
                  <i className="fa fa-user"></i> Logout
                </Link>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    HomePageData: state.HomePageContents,
    UserProfile: state.LoginDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setOnLoginDetails: (p) => {
      dispatch(setLoginDetails(p));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);

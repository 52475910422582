import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { serverLink } from "../../resources/Url";
import { showAlert } from "../../pages/sweetalert/sweetalert";
import axios from "axios";
import { setLoginDetails } from "../../actions/Actions";
import { connect } from "react-redux";
import NavBar from "../NavBar";
import Footer from "../Footer";
import Table from "../../pages/table/table";
import Header from "../Header";
import HomeContentLoader from "../HomeContentLoader/HomeContentLoader";
import SubmittingLoader from "../SubmittingLoader/SubmittingLoader";

function AddAssets(props) {
  const [position, setPosition] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);
  const [lgas, setLGAs] = useState([]);

  const [states] = useState(
    typeof props?.HomePageData.states !== "undefined"
      ? props?.HomePageData.states
      : []
  );

  const [lga] = useState(
    typeof props?.HomePageData.lga !== "undefined"
      ? props?.HomePageData.lga
      : []
  );

  const [staff] = useState(
    typeof props?.HomePageData.staff !== "undefined"
      ? props?.HomePageData.staff
      : []
  );

  const [assetsType] = useState(
    typeof props?.HomePageData.assetsType !== "undefined"
      ? props?.HomePageData.assetsType
      : []
  );

  const [createItem, setCreateItem] = useState({
    AssetId: "",
    Title: "",
    Description: "",
    AssetTypeId: "",
    StateId: "",
    LGAId: "",
    StaffId: "",
    Image: "",
    QuantityDescription: "",
    Status: "",
    Contact: "",
    Year: "",
  });

  const resetItem = () => {
    setCreateItem({
      ...createItem,
      AssetId: "",
      Title: "",
      Description: "",
      AssetTypeId: "",
      StateId: "",
      LGAId: "",
      StaffId: "",
      QuantityDescription: "",
      Status: "",
      Contact: "",
      Year: "",
    });
  };

  const [datatable, setDatatable] = useState({
    columns: [
      {
        label: "S/N",
        field: "sn",
      },
      {
        label: "Title",
        field: "Title",
      },
      {
        label: "Description",
        field: "Description",
      },
      {
        label: "Action",
        field: "action",
      },
    ],
    rows: [],
  });

  const [formData, setFormData] = useState([]); // Initial input field

  const handleInputChange = (index, value) => {
    const updatedFormData = [...formData];
    updatedFormData[index] = { ...updatedFormData[index], value };
    setFormData(updatedFormData);
  };

  const handleAddField = () => {
    setFormData([...formData, { AssetId: "", value: "" }]);
  };

  const handleRemoveField = (index) => {
    const updatedFormData = [...formData];
    updatedFormData.splice(index, 1);
    setFormData(updatedFormData);
  };

  const onEdit = async (e) => {
    const id = e.target.id;
    const value = e.target.value;

    if (id === "StateId") {
      const selectedStateId = value;
      setCreateItem({
        ...createItem,
        StateId: selectedStateId,
        LGAId: "", // Reset LGAId when the state changes
      });

      try {
        const lgaData = lga.filter(
          (x) => x.StateId === Number(selectedStateId)
        );

        setLGAs(lgaData);
      } catch (error) {
        console.error("Error fetching LGAs:", error);
      }
    } else if (id === "LGAId") {
      setCreateItem({
        ...createItem,
        LGAId: value,
      });
    } else {
      setCreateItem({
        ...createItem,
        [id]: value,
      });
    }
  };

  const getProjects = async () => {
    await axios
      .get(`${serverLink}shared/get/assets`)
      .then((result) => {
        if (result.data.length > 0) {
          let rows = [];
          result.data.map((staff, index) => {
            rows.push({
              sn: index + 1,
              AssetId: staff.AssetId,
              Title: staff.Title,
              Description: staff.Description,
              AssetTypeId: staff.AssetTypeId,
              StateId: staff.StateId,
              Contact: staff.Contact,
              LGAId: staff.LGAId,
              StaffId: staff.StaffId,
              QuantityDescription: staff.QuantityDescription,
              Status: staff.Status,
              Year: staff.Year,
              action: (
                <button
                  type="button"
                  className="btn btn-primary"
                  style={{ backgroundColor: "#008435" }}
                  data-toggle="modal"
                  data-target="#myModal"
                  onClick={() =>
                    setCreateItem({
                      AssetId: staff.AssetId,
                      Title: staff.Title,
                      Description: staff.Description,
                      AssetTypeId: staff.AssetTypeId,
                      Contact: staff.Contact,
                      StateId: staff.StateId,
                      LGAId: staff.LGAId,
                      StaffId: staff.StaffId,
                      QuantityDescription: staff.QuantityDescription,
                      Status: staff.Status,
                      Year: staff.Year,
                    })
                  }
                >
                  Edit
                </button>
              ),
            });
          });

          setDatatable({
            ...datatable,
            columns: datatable.columns,
            rows: rows,
          });
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("NETWORK ERROR");
      });
  };

  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState(
    typeof props?.UserProfile !== "undefined" ? props?.UserProfile : []
  );

  useEffect(() => {
    getProjects().then((r) => {});
    const timeoutId = setTimeout(() => {
      setPosition(1);
      setIsLoading(false);
      if (currentUser.length === 0) {
        navigate("/auth");
      }
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [currentUser]);

  const onSubmit = async (e) => {
    setIsSubmittingForm(true);
    e.preventDefault();
    for (let key in createItem) {
      if (
        createItem.hasOwnProperty(key) &&
        key !== "AssetId" &&
        key !== "Image"
      ) {
        if (createItem[key] === "") {
          await showAlert("EMPTY FIELD", `Please enter ${key}`, "error");
          setIsSubmittingForm(false);
          return false;
        }
      }
    }

    const sendData = {
      AssetId: createItem.AssetId,
      Title: createItem.Title,
      Description: createItem.Description,
      AssetTypeId: createItem.AssetTypeId,
      Contact: createItem.Contact,
      StateId: createItem.StateId,
      LGAId: createItem.LGAId,
      StaffId: createItem.StaffId,
      QuantityDescription: Number(createItem.QuantityDescription),
      Status: createItem.Status,
    };

    if (createItem.AssetId === "") {
      axios
        .post(`${serverLink}assets/add`, sendData)
        .then(async (res) => {
          if (res.data.message === "success") {
            if (res.data.assetId && formData.length > 0) {
              const dataToSubmit = formData.map((field) => ({
                ...field,
                AssetId: res.data.assetId,
              }));
              axios
                .post(`${serverLink}assets/extra/fields`, dataToSubmit)
                .then(async (res) => {});
            }
            await getProjects();
            await showAlert("Success", `Asset Added Successfully`, "success");
            setFormData([]);
            resetItem();
            setIsSubmittingForm(false);
          }
          if (res.data.message === "exist") {
            await showAlert(
              "Error",
              `Asset with given details already exist!`,
              "error"
            );
            setIsSubmittingForm(false);
          }
        })
        .catch((error) => {
          console.log("Error", error);
        });
    }
    if (createItem.AssetId !== "") {
      await axios
        .patch(`${serverLink}assets/update`, sendData)
        .then((result) => {
          if (result.data.message === "success") {
            getProjects();
            showAlert("Success", "Asset Updated Successfully", "success");
            setFormData([]);
            resetItem();
            setIsSubmittingForm(false);
          } else {
            showAlert(
              "ERROR",
              "Something went wrong. Please try again!",
              "error"
            );
            setIsSubmittingForm(false);
          }
        })
        .catch((error) => {
          showAlert(
            "NETWORK ERROR",
            "Please check your connection and try again!",
            "error"
          );
          setIsSubmittingForm(false);
        });
    }
  };

  return (
    <>
      {isLoading ? (
        <HomeContentLoader />
      ) : (
        <div id="wrapper">
          <Header />
          <NavBar />
          <div id="page-wrapper">
            <div class="header">
              <h1 class="page-header">Manage Assets</h1>
            </div>
            <div id="page-inner">
              <div class="row">
                <div class="col-md-12">
                  <div class="panel panel-default">
                    <div class="panel-heading"></div>

                    <div class="panel-body">
                      <div className="panel-body">
                        <button
                          style={{
                            backgroundColor: "#008435",
                            float: "right",
                          }}
                          className="btn btn-primary"
                          data-toggle="modal"
                          data-target="#myModal"
                          onClick={resetItem}
                        >
                          Add Asset
                        </button>
                        {isSubmittingForm && <SubmittingLoader />}
                        <div
                          className="modal fade"
                          id="myModal"
                          tabIndex="-1"
                          role="dialog"
                          aria-labelledby="myModalLabel"
                          aria-hidden="true"
                        >
                          <div className="modal-dialog">
                            <div
                              className="modal-content"
                              style={{
                                width: "700px",
                              }}
                            >
                              <div className="modal-header">
                                <button
                                  type="button"
                                  className="close"
                                  data-dismiss="modal"
                                  aria-hidden="true"
                                >
                                  &times;
                                </button>
                                <h4 className="modal-title" id="myModalLabel">
                                  Asset Component
                                </h4>
                              </div>
                              <div className="modal-body">
                                <div className="col-lg-6 mb-4">
                                  <div className="form-group">
                                    <input
                                      className="form-control"
                                      id="Title"
                                      type="text"
                                      placeholder="Title"
                                      value={createItem.Title}
                                      onChange={onEdit}
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-6 mb-4">
                                  <div className="form-group">
                                    <select
                                      className="form-control"
                                      id="AssetTypeId"
                                      name="AssetTypeId"
                                      onChange={onEdit}
                                      value={createItem.AssetTypeId}
                                    >
                                      <option value="">
                                        Select Asset Type
                                      </option>
                                      {assetsType.length > 0 &&
                                        assetsType.map((item, index) => {
                                          return (
                                            <option
                                              key={item.AssetTypeId}
                                              value={item.AssetTypeId}
                                            >
                                              {item.Title}
                                            </option>
                                          );
                                        })}
                                    </select>
                                  </div>
                                </div>
                                <div className="col-lg-6 mb-4">
                                  <div className="form-group">
                                    <select
                                      className="form-control"
                                      id="StaffId"
                                      name="StaffId"
                                      onChange={onEdit}
                                      value={createItem.StaffId}
                                    >
                                      <option value="">
                                        Assign Staff to Asset
                                      </option>
                                      {staff.length > 0 &&
                                        staff.map((item, index) => {
                                          return (
                                            <option
                                              key={item.StaffID}
                                              value={item.StaffID}
                                            >
                                              {item.FullName}
                                            </option>
                                          );
                                        })}
                                    </select>
                                  </div>
                                </div>
                                <div className="col-lg-6 ">
                                  <div className="form-group">
                                    <select
                                      id="Status"
                                      className="form-control"
                                      value={createItem.Status}
                                      onChange={onEdit}
                                    >
                                      <option value="">Select Status</option>
                                      <option value="1">Active</option>
                                      <option value="0">InActive</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="col-lg-6 mb-4">
                                  <div className="form-group">
                                    <select
                                      className="form-control"
                                      id="StateId"
                                      name="StateId"
                                      onChange={onEdit}
                                      value={createItem.StateId}
                                    >
                                      <option value="">Select State</option>
                                      {states.length > 0 &&
                                        states.map((item, index) => {
                                          return (
                                            <option
                                              key={item.StateId}
                                              value={item.StateId}
                                            >
                                              {item.Title}
                                            </option>
                                          );
                                        })}
                                    </select>
                                  </div>
                                </div>
                                <div className="col-lg-6 mb-4">
                                  <div className="form-group">
                                    <select
                                      className="form-control"
                                      id="LGAId"
                                      name="LGAId"
                                      disabled={createItem.StateId === ""}
                                      onChange={onEdit}
                                      value={createItem.LGAId}
                                    >
                                      <option value="">Select LGA</option>
                                      {lgas.length > 0 &&
                                        lgas.map((item, index) => {
                                          return (
                                            <option
                                              key={item.LGAId}
                                              value={item.LGAId}
                                            >
                                              {item.Title}
                                            </option>
                                          );
                                        })}
                                    </select>
                                  </div>
                                </div>
                                <div className="col-lg-6 mb-4">
                                  <div className="form-group">
                                    <input
                                      className="form-control"
                                      id="QuantityDescription"
                                      type="number"
                                      placeholder="Quantity (Optional)"
                                      value={createItem.QuantityDescription}
                                      onChange={onEdit}
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-6 mb-4">
                                  <div className="form-group">
                                    <input
                                      className="form-control"
                                      id="Year"
                                      type="number"
                                      placeholder="Year"
                                      value={createItem.Year}
                                      onChange={onEdit}
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-12 mb-4">
                                  <div className="form-group">
                                    <textarea
                                      className="form-control"
                                      id="Description"
                                      type="text"
                                      style={{
                                        height: "120px",
                                      }}
                                      placeholder="Asset Description"
                                      value={createItem.Description}
                                      onChange={onEdit}
                                    ></textarea>
                                  </div>
                                </div>
                                <div className="col-lg-12 mb-4">
                                  <div className="form-group">
                                    <textarea
                                      className="form-control"
                                      id="Contact"
                                      type="text"
                                      style={{
                                        height: "120px",
                                      }}
                                      placeholder="Asset Contact Information"
                                      value={createItem.Contact}
                                      onChange={onEdit}
                                    ></textarea>
                                  </div>
                                </div>

                                {formData.map((field, index) => (
                                  <div className="col-lg-12 mb-4">
                                    <div className="form-group">
                                      <div key={index}>
                                        <input
                                          className="form-control"
                                          type="text"
                                          value={field.value}
                                          onChange={(e) =>
                                            handleInputChange(
                                              index,
                                              e.target.value
                                            )
                                          }
                                        />
                                        <button
                                          onClick={() =>
                                            handleRemoveField(index)
                                          }
                                        >
                                          Remove
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>

                              <div className="modal-footer">
                                <button
                                  type="button"
                                  className="btn btn-default"
                                  data-dismiss="modal"
                                  style={{
                                    backgroundColor: "#da3535",
                                    color: "white",
                                  }}
                                  onClick={getProjects}
                                >
                                  Close
                                </button>
                                <button
                                  type="button"
                                  style={{ backgroundColor: "#008435" }}
                                  className="btn btn-primary"
                                  onClick={onSubmit}
                                >
                                  Save changes
                                </button>
                                <button
                                  type="button"
                                  style={{ backgroundColor: "#008435" }}
                                  className="btn btn-primary"
                                  onClick={handleAddField}
                                >
                                  Add Field
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div class="panel panel-default">
                          <div class="panel-heading">Sliders List</div>
                          <div className="card-body pt-0">
                            <Table data={datatable} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    HomePageData: state.HomePageContents,
    UserProfile: state.LoginDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setOnLoginDetails: (p) => {
      dispatch(setLoginDetails(p));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddAssets);

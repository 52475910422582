import { projectCode } from "../../resources/Url";

export const LoginDetailsReducer = (state = [], action) => {
    switch (action.type) {
        case `SET_${projectCode}_LOGIN_DETAILS`:
            return action.payload;
        default:
            return state;
    }
};

export const CustomerIdentityDetailsReducer = (state = [], action) => {
    switch (action.type) {
        case `SET_${projectCode}_CUSTOMER_IDENTITY_DETAILS`:
            return action.payload;
        default:
            return state;
    }
};

export const PermissionDetailsReducer = (state = [], action) => {
    switch (action.type) {
        case `SET_${projectCode}_PERMISSION_DETAILS`:
            return action.payload;
        default:
            return state;
    }
};

export const HomePageContentsReducer = (state=[], action)=>{
    switch(action.type){
        case "SET_HOMEPAGE_CONTENTS":
            return action.payload;
        default:
            return state;
    }
}
